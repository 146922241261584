import { Entity } from 'src/api/api-gc/model';
import { RenderTreeWithParentId } from 'src/interfaces';

const EntityHelper = {
  getEntitiesTree: (entities: Entity[]): RenderTreeWithParentId[] => {
    if (entities?.length) {
      const clone: RenderTreeWithParentId[] = entities.map((e) => {
        return {
          id: String(e.entityId as number),
          code: e.entityCode,
          label: e.entityName || '',
          parentId: String(e.entityParentId || 0),
        } as RenderTreeWithParentId;
      });

      return clone.filter((entity) => {
        entity.children = clone.filter((item) => {
          return item.parentId === entity.id;
        });

        return entity.parentId === '0';
      });
    } else {
      return [];
    }
  },
};

export default EntityHelper;
