import {
  ValidationStateResponse,
  ValidationStateSchema,
  ValidatorType,
} from 'src/interfaces';
import ExpressionHelper from './ExpressionHelper';

const getValue: any = (obj: any, path: any) => {
  if (!path || !obj) return obj;
  const properties = path.split('.');
  return getValue(obj[properties.shift()], properties.join('.'));
};

const luhnCheck = (str: string) => {
  if (!str) {
    return false;
  } else {
    try {
      const arr = str
        .split('')
        .reverse()
        .map((x) => Number.parseInt(x));
      const lastDigit = arr.shift() as number;
      let sum = arr.reduce(
        (acc, val, i) =>
          i % 2 !== 0 ? acc + val : acc + ((val *= 2) > 9 ? val - 9 : val),
        0
      );
      sum += lastDigit;
      return sum % 10 === 0;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
};

const ValidationStateSchemaHelper = {
  isRequired: (
    schema?: ValidationStateSchema,
    field?: string
  ): boolean | undefined => {
    if (schema && field) {
      return schema[field] ? schema[field].required : undefined;
    }
  },
  maxLength: (
    schema?: ValidationStateSchema,
    field?: string
  ): number | undefined => {
    if (schema && field) {
      return schema[field] ? schema[field].maxLength : undefined;
    }
  },
  validateState: (
    schema: ValidationStateSchema,
    data: any
  ): ValidationStateResponse => {
    let hasErrorInState = false;
    const errors: Record<string, string> = {};
    for (const key in schema) {
      const isInputFieldRequired: boolean = schema[key].required;

      if (isInputFieldRequired && !getValue(data, key)) {
        hasErrorInState = true;
        errors[key] = schema[key].error || '';
      } else if (!!schema[key].validator) {
        for (const validator of (Array.isArray(schema[key].validator)
          ? schema[key].validator
          : [schema[key].validator]) as ValidatorType[]) {
          switch (validator.type) {
            case 'regex': {
              if (
                !new RegExp(validator.value as string).test(getValue(data, key))
              ) {
                hasErrorInState = true;
                errors[key] = validator.error || '';
              }
              break;
            }
            case 'function': {
              switch (validator.value) {
                case 'luhncheck': {
                  if (!luhnCheck(getValue(data, key))) {
                    hasErrorInState = true;
                    errors[key] = validator.error || '';
                  }
                  break;
                }
                default:
                  break;
              }
              break;
            }
            case 'expr': {
              console.log(validator.value);
              console.log(data);
              console.log(ExpressionHelper.evaluate(validator.value, data));
              if (!ExpressionHelper.evaluate(validator.value, data)) {
                hasErrorInState = true;
                errors[key] = validator.error || '';
              }
              break;
            }
            default:
              break;
          }
        }
      }
    }

    return { hasError: hasErrorInState, errors };
  },
};

export default ValidationStateSchemaHelper;
