/**
 * gc-ws-backoffice
 * GC WS BackOffice API.
 *
 * OpenAPI spec version: 5.0.0-SNAPSHOT
 * Contact: acuvillier@sbeglobalservice.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {
  HeaderRule,
  LogisticConfigRule,
  LogisticDeliveryRule,
  LogisticProcessRule,
  ProcessRule,
  QuoteOptionRule,
  ReturnReasonRule,
  ScreeningStateRule,
  SearchApiRulesRequest,
  SearchHeaderRulesResponse,
  SearchLogisticConfigRulesResponse,
  SearchLogisticDeliveryRulesRequest,
  SearchLogisticDeliveryRulesResponse,
  SearchLogisticProcessRulesResponse,
  SearchProcessRulesRequest,
  SearchProcessRulesResponse,
  SearchQuoteOptionRulesRequest,
  SearchQuoteOptionRulesResponse,
  SearchReturnReasonRulesResponse,
  SearchScreeningStateRulesResponse,
  SearchStockCodeRulesRequest,
  SearchStockCodeRulesResponse,
  SearchTemplateRulesRequest,
  SearchTemplateRulesResponse,
  SearchTransitionPrealertRulesResponse,
  SearchTransitionStatusRulesResponse,
  SearchTransitionTaskRulesResponse,
  SearchTreatmentOperationRulesRequest,
  SearchTreatmentOperationRulesResponse,
  SearchWarrantyRulesResponse,
  TemplateRule,
  TransitionPrealertRule,
  TransitionStatusRule,
  TransitionTaskRule,
  WarrantyRule,
} from '../model';

import { Configuration } from '../configuration';
import config from 'src/config';
import axios, { AxiosResponse } from 'axios';
import { SearchApiRulesResponse } from '../model/searchApiRulesResponse';
import { SearchWarrantyRulesRequest } from '../model/searchWarrantyRulesRequest';
import { SearchWarrantyCalculationRulesRequest } from '../model/searchWarrantyCalculationRulesRequest';
import { SearchWarrantyCalculationRulesResponse } from '../model/searchWarrantyCalculationRulesResponse';
import { SearchListValueRulesResponse } from '../model/searchListValueRulesResponse';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APIRulesService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * Creates a transitionStatusRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createTransitionStatusRule(
    token: string,
    orgCode: string,
    body?: TransitionStatusRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TransitionStatusRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createTransitionStatusRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createTransitionStatusRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.post<TransitionStatusRule>(
      `${this.basePath}/rules/transitionStatusRules`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a transitionTaskRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createTransitionTaskRule(
    token: string,
    orgCode: string,
    body?: TransitionTaskRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TransitionTaskRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createTransitionTaskRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createTransitionTaskRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    headers['Accept'] = this.configuration.selectHeaderAccept([
      'application/json',
    ]);

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.post<TransitionTaskRule>(
      `${this.basePath}/rules/transitionTaskRules`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a transitionStatusRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param rulesId
   */
  public deleteTransitionStatusRule(
    token: string,
    orgCode: string,
    rulesId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteTransitionStatusRule.'
      );
    }

    if (!orgCode) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteTransitionStatusRule.'
      );
    }

    if (rulesId) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling deleteTransitionStatusRule.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.delete<any>(
      `${this.basePath}/rules/transitionStatusRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a transitionTaskRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param rulesId
   */
  public deleteTransitionTaskRule(
    token: string,
    orgCode: string,
    rulesId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteTransitionTaskRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteTransitionTaskRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling deleteTransitionTaskRule.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.delete<any>(
      `${this.basePath}/rules/transitionTaskRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a transitionStatusRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param rulesId
   * @param mapLevel
   */
  public getTransitionStatusRule(
    token: string,
    orgCode: string,
    rulesId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TransitionStatusRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTransitionStatusRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTransitionStatusRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling getTransitionStatusRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.get<TransitionStatusRule>(
      `${this.basePath}/rules/transitionStatusRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search transitionStatusRules
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param enable
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param inTransitionCode
   * @param inObjectType
   * @param inCurrentStatusCode
   * @param inProcessCode
   * @param inServiceType
   * @param inChannel
   * @param inEntityType
   * @param inEntityNetwork
   * @param inEntityCountry
   * @param inEntityParentCode
   * @param inEntityCode
   * @param inCustomerType
   * @param inCustomerSegment
   * @param inCustomerNetwork
   * @param inCustomerCountry
   * @param inCustomerId
   * @param inCatalogCatItemId
   * @param inCatalogModelId
   * @param inCatalogProductId
   * @param inManufacturerCode
   * @param inCurrentReasonCode
   * @param inGradeCode
   * @param inLogisticLeg
   * @param inLogisticScenario
   * @param outObjectType
   * @param outStatusCode
   * @param outReasonCode
   * @param outFinalStateCode
   */
  public getTransitionStatusRules(
    token: string,
    orgCode: string,
    enable?: boolean,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    inTransitionCode?: string,
    inObjectType?: string,
    inCurrentStatusCode?: string,
    inProcessCode?: string,
    inServiceType?: string,
    inChannel?: string,
    inEntityType?: string,
    inEntityNetwork?: string,
    inEntityCountry?: string,
    inEntityParentCode?: string,
    inEntityCode?: string,
    inCustomerType?: string,
    inCustomerSegment?: string,
    inCustomerNetwork?: string,
    inCustomerCountry?: string,
    inCustomerId?: number,
    inCatalogCatItemId?: number,
    inCatalogModelId?: number,
    inCatalogProductId?: number,
    inManufacturerCode?: string,
    inCurrentReasonCode?: string,
    inGradeCode?: string,
    inLogisticLeg?: string,
    inLogisticScenario?: string,
    outObjectType?: string,
    outStatusCode?: string,
    outReasonCode?: string,
    outFinalStateCode?: string
  ): Promise<AxiosResponse<SearchTransitionStatusRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTransitionStatusRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTransitionStatusRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (inTransitionCode !== undefined && inTransitionCode !== null) {
      queryParameters.append('inTransitionCode', inTransitionCode as any);
    }
    if (inObjectType !== undefined && inObjectType !== null) {
      queryParameters.append('inObjectType', inObjectType as any);
    }
    if (inCurrentStatusCode !== undefined && inCurrentStatusCode !== null) {
      queryParameters.append('inCurrentStatusCode', inCurrentStatusCode as any);
    }
    if (inProcessCode !== undefined && inProcessCode !== null) {
      queryParameters.append('inProcessCode', inProcessCode as any);
    }
    if (inServiceType !== undefined && inServiceType !== null) {
      queryParameters.append('inServiceType', inServiceType as any);
    }
    if (inChannel !== undefined && inChannel !== null) {
      queryParameters.append('inChannel', inChannel as any);
    }
    if (inEntityType !== undefined && inEntityType !== null) {
      queryParameters.append('inEntityType', inEntityType as any);
    }
    if (inEntityNetwork !== undefined && inEntityNetwork !== null) {
      queryParameters.append('inEntityNetwork', inEntityNetwork as any);
    }
    if (inEntityCountry !== undefined && inEntityCountry !== null) {
      queryParameters.append('inEntityCountry', inEntityCountry as any);
    }
    if (inEntityParentCode !== undefined && inEntityParentCode !== null) {
      queryParameters.append('inEntityParentCode', inEntityParentCode as any);
    }
    if (inEntityCode !== undefined && inEntityCode !== null) {
      queryParameters.append('inEntityCode', inEntityCode as any);
    }
    if (inCustomerType !== undefined && inCustomerType !== null) {
      queryParameters.append('inCustomerType', inCustomerType as any);
    }
    if (inCustomerSegment !== undefined && inCustomerSegment !== null) {
      queryParameters.append('inCustomerSegment', inCustomerSegment as any);
    }
    if (inCustomerNetwork !== undefined && inCustomerNetwork !== null) {
      queryParameters.append('inCustomerNetwork', inCustomerNetwork as any);
    }
    if (inCustomerCountry !== undefined && inCustomerCountry !== null) {
      queryParameters.append('inCustomerCountry', inCustomerCountry as any);
    }
    if (inCustomerId !== undefined && inCustomerId !== null) {
      queryParameters.append('inCustomerId', inCustomerId as any);
    }
    if (inCatalogCatItemId !== undefined && inCatalogCatItemId !== null) {
      queryParameters.append('inCatalogCatItemId', inCatalogCatItemId as any);
    }
    if (inCatalogModelId !== undefined && inCatalogModelId !== null) {
      queryParameters.append('inCatalogModelId', inCatalogModelId as any);
    }
    if (inCatalogProductId !== undefined && inCatalogProductId !== null) {
      queryParameters.append('inCatalogProductId', inCatalogProductId as any);
    }
    if (inManufacturerCode !== undefined && inManufacturerCode !== null) {
      queryParameters.append('inManufacturerCode', inManufacturerCode as any);
    }
    if (inCurrentReasonCode !== undefined && inCurrentReasonCode !== null) {
      queryParameters.append('inCurrentReasonCode', inCurrentReasonCode as any);
    }
    if (inGradeCode !== undefined && inGradeCode !== null) {
      queryParameters.append('inGradeCode', inGradeCode as any);
    }
    if (inLogisticLeg !== undefined && inLogisticLeg !== null) {
      queryParameters.append('inLogisticLeg', inLogisticLeg as any);
    }
    if (inLogisticScenario !== undefined && inLogisticScenario !== null) {
      queryParameters.append('inLogisticScenario', inLogisticScenario as any);
    }
    if (outObjectType !== undefined && outObjectType !== null) {
      queryParameters.append('outObjectType', outObjectType as any);
    }
    if (outStatusCode !== undefined && outStatusCode !== null) {
      queryParameters.append('outStatusCode', outStatusCode as any);
    }
    if (outReasonCode !== undefined && outReasonCode !== null) {
      queryParameters.append('outReasonCode', outReasonCode as any);
    }
    if (outFinalStateCode !== undefined && outFinalStateCode !== null) {
      queryParameters.append('outFinalStateCode', outFinalStateCode as any);
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.get<SearchTransitionStatusRulesResponse>(
      `${this.basePath}/rules/transitionStatusRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search transitionStatusRules
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param enable
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param criteria
   */
  public searchTransitionStatusRules(
    token: string,
    orgCode: string,
    enable?: boolean,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    criteria?: any
  ): Promise<AxiosResponse<SearchTransitionStatusRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTransitionStatusRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTransitionStatusRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (criteria) {
      for (const [key, value] of Object.entries(criteria)) {
        queryParameters.append(key, value as any);
      }
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.get<SearchTransitionStatusRulesResponse>(
      `${this.basePath}/rules/transitionStatusRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a transitionTaskRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param rulesId
   * @param mapLevel
   */
  public getTransitionTaskRule(
    token: string,
    orgCode: string,
    rulesId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TransitionTaskRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTransitionTaskRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTransitionTaskRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling getTransitionTaskRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.get<TransitionTaskRule>(
      `${this.basePath}/rules/transitionTaskRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search transitionTaskRules
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param enable
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param rulesName
   * @param outTaskCode
   * @param outTaskReason
   * @param outTaskValue
   * @param inTransitionCode
   * @param inTransitionGroup
   * @param inStatusCode
   * @param inProcessCode
   * @param inServiceType
   * @param inChannel
   * @param inEntityType
   * @param inEntityNetwork
   * @param inEntityCountry
   * @param inEntityParentId
   * @param inEntityId
   * @param inCustomerType
   * @param inCustomerSegment
   * @param inCustomerNetwork
   * @param inCustomerCountry
   * @param inCustomerId
   * @param inCatalogCatItemId
   * @param inCatalogModelId
   * @param inCatalogProductId
   * @param inManufacturerId
   * @param inHaveLoaner
   * @param inWarrantyCode
   */
  public getTransitionTaskRules(
    token: string,
    orgCode: string,
    enable?: boolean,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    rulesName?: string,
    outTaskCode?: string,
    outTaskReason?: string,
    outTaskValue?: string,
    inTransitionCode?: string,
    inTransitionGroup?: string,
    inStatusCode?: string,
    inProcessCode?: string,
    inServiceType?: string,
    inChannel?: string,
    inEntityType?: number,
    inEntityNetwork?: string,
    inEntityCountry?: string,
    inEntityParentId?: number,
    inEntityId?: number,
    inCustomerType?: string,
    inCustomerSegment?: string,
    inCustomerNetwork?: string,
    inCustomerCountry?: string,
    inCustomerId?: number,
    inCatalogCatItemId?: number,
    inCatalogModelId?: number,
    inCatalogProductId?: number,
    inManufacturerId?: number,
    inHaveLoaner?: boolean,
    inWarrantyCode?: string
  ): Promise<AxiosResponse<SearchTransitionTaskRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTransitionTaskRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTransitionTaskRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (rulesName !== undefined && rulesName !== null) {
      queryParameters.append('rulesName', rulesName as any);
    }
    if (outTaskCode !== undefined && outTaskCode !== null) {
      queryParameters.append('outTaskCode', outTaskCode as any);
    }
    if (outTaskReason !== undefined && outTaskReason !== null) {
      queryParameters.append('outTaskReason', outTaskReason as any);
    }
    if (outTaskValue !== undefined && outTaskValue !== null) {
      queryParameters.append('outTaskValue', outTaskValue as any);
    }
    if (inTransitionCode !== undefined && inTransitionCode !== null) {
      queryParameters.append('inTransitionCode', inTransitionCode as any);
    }
    if (inTransitionGroup !== undefined && inTransitionGroup !== null) {
      queryParameters.append('inTransitionGroup', inTransitionGroup as any);
    }
    if (inStatusCode !== undefined && inStatusCode !== null) {
      queryParameters.append('inStatusCode', inStatusCode as any);
    }
    if (inProcessCode !== undefined && inProcessCode !== null) {
      queryParameters.append('inProcessCode', inProcessCode as any);
    }
    if (inServiceType !== undefined && inServiceType !== null) {
      queryParameters.append('inServiceType', inServiceType as any);
    }
    if (inChannel !== undefined && inChannel !== null) {
      queryParameters.append('inChannel', inChannel as any);
    }
    if (inEntityType !== undefined && inEntityType !== null) {
      queryParameters.append('inEntityType', inEntityType as any);
    }
    if (inEntityNetwork !== undefined && inEntityNetwork !== null) {
      queryParameters.append('inEntityNetwork', inEntityNetwork as any);
    }
    if (inEntityCountry !== undefined && inEntityCountry !== null) {
      queryParameters.append('inEntityCountry', inEntityCountry as any);
    }
    if (inEntityParentId !== undefined && inEntityParentId !== null) {
      queryParameters.append('inEntityParentId', inEntityParentId as any);
    }
    if (inEntityId !== undefined && inEntityId !== null) {
      queryParameters.append('inEntityId', inEntityId as any);
    }
    if (inCustomerType !== undefined && inCustomerType !== null) {
      queryParameters.append('inCustomerType', inCustomerType as any);
    }
    if (inCustomerSegment !== undefined && inCustomerSegment !== null) {
      queryParameters.append('inCustomerSegment', inCustomerSegment as any);
    }
    if (inCustomerNetwork !== undefined && inCustomerNetwork !== null) {
      queryParameters.append('inCustomerNetwork', inCustomerNetwork as any);
    }
    if (inCustomerCountry !== undefined && inCustomerCountry !== null) {
      queryParameters.append('inCustomerCountry', inCustomerCountry as any);
    }
    if (inCustomerId !== undefined && inCustomerId !== null) {
      queryParameters.append('inCustomerId', inCustomerId as any);
    }
    if (inCatalogCatItemId !== undefined && inCatalogCatItemId !== null) {
      queryParameters.append('inCatalogCatItemId', inCatalogCatItemId as any);
    }
    if (inCatalogModelId !== undefined && inCatalogModelId !== null) {
      queryParameters.append('inCatalogModelId', inCatalogModelId as any);
    }
    if (inCatalogProductId !== undefined && inCatalogProductId !== null) {
      queryParameters.append('inCatalogProductId', inCatalogProductId as any);
    }
    if (inManufacturerId !== undefined && inManufacturerId !== null) {
      queryParameters.append('inManufacturerId', inManufacturerId as any);
    }
    if (inHaveLoaner !== undefined && inHaveLoaner !== null) {
      queryParameters.append('inHaveLoaner', inHaveLoaner as any);
    }
    if (inWarrantyCode !== undefined && inWarrantyCode !== null) {
      queryParameters.append('inWarrantyCode', inWarrantyCode as any);
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    headers['Accept'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.get<SearchTransitionTaskRulesResponse>(
      `${this.basePath}/rules/transitionTaskRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a transitionStatusRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyTransitionStatusRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: TransitionStatusRule,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<TransitionStatusRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyTransitionStatusRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyTransitionStatusRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling modifyTransitionStatusRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: any } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    headers['Accept'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.patch<TransitionStatusRule>(
      `${this.basePath}/rules/transitionStatusRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a transitionTaskRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyTransitionTaskRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: TransitionTaskRule,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<TransitionTaskRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyTransitionTaskRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyTransitionTaskRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling modifyTransitionTaskRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: any } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    headers['Accept'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.patch<TransitionTaskRule>(
      `${this.basePath}/rules/transitionTaskRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a transitionStatusRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   */
  public replaceTransitionStatusRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: TransitionStatusRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TransitionStatusRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceTransitionStatusRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceTransitionStatusRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling replaceTransitionStatusRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    headers['Accept'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.put<TransitionStatusRule>(
      `${this.basePath}/rules/transitionStatusRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a transitionTaskRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   */
  public replaceTransitionTaskRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: TransitionTaskRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TransitionTaskRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceTransitionTaskRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceTransitionTaskRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling replaceTransitionTaskRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    headers['Accept'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.put<TransitionTaskRule>(
      `${this.basePath}/rules/transitionTaskRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a headerRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createHeaderRule(
    token: string,
    orgCode: string,
    body?: HeaderRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<HeaderRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createHeaderRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createHeaderRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.post<HeaderRule>(`${this.basePath}/rules/headerRules`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Delete a headerRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param headerId
   */
  public deleteHeaderRule(
    token: string,
    orgCode: string,
    headerId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteHeaderRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteHeaderRule.'
      );
    }

    if (headerId === null || headerId === undefined) {
      throw new Error(
        'Required parameter headerId was null or undefined when calling deleteHeaderRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.delete<any>(
      `${this.basePath}/rules/headerRules/${encodeURIComponent(
        String(headerId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a headerRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param headerId
   * @param mapLevel
   */
  public getHeaderRule(
    token: string,
    orgCode: string,
    headerId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<HeaderRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getHeaderRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getHeaderRule.'
      );
    }

    if (headerId === null || headerId === undefined) {
      throw new Error(
        'Required parameter headerId was null or undefined when calling getHeaderRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.get<HeaderRule>(
      `${this.basePath}/rules/headerRules/${encodeURIComponent(
        String(headerId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search headerRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param ruleType
   * @param ruleCode
   * @param ruleName
   * @param description
   * @param dictionaryDataDomain
   */
  public getHeaderRules(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    ruleType?: string,
    ruleCode?: string,
    ruleName?: string,
    description?: string,
    dictionaryDataDomain?: string
  ): Promise<AxiosResponse<SearchHeaderRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getHeaderRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getHeaderRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (ruleType !== undefined && ruleType !== null) {
      queryParameters.append('ruleType', ruleType as any);
    }
    if (ruleCode !== undefined && ruleCode !== null) {
      queryParameters.append('ruleCode', ruleCode as any);
    }
    if (ruleName !== undefined && ruleName !== null) {
      queryParameters.append('ruleName', ruleName as any);
    }
    if (description !== undefined && description !== null) {
      queryParameters.append('description', description as any);
    }
    if (dictionaryDataDomain !== undefined && dictionaryDataDomain !== null) {
      queryParameters.append(
        'dictionaryDataDomain',
        dictionaryDataDomain as any
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.get<SearchHeaderRulesResponse>(
      `${this.basePath}/rules/headerRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a headerRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param headerId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyHeaderRule(
    token: string,
    orgCode: string,
    headerId: number,
    body?: HeaderRule,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<HeaderRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyHeaderRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyHeaderRule.'
      );
    }

    if (headerId === null || headerId === undefined) {
      throw new Error(
        'Required parameter headerId was null or undefined when calling modifyHeaderRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: any } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.patch<HeaderRule>(
      `${this.basePath}/rules/headerRules/${encodeURIComponent(
        String(headerId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a headerRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param headerId
   * @param body
   * @param mapLevel
   */
  public replaceHeaderRule(
    token: string,
    orgCode: string,
    headerId: number,
    body?: HeaderRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<HeaderRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceHeaderRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceHeaderRule.'
      );
    }

    if (headerId === null || headerId === undefined) {
      throw new Error(
        'Required parameter headerId was null or undefined when calling replaceHeaderRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.put<HeaderRule>(
      `${this.basePath}/rules/headerRules/${encodeURIComponent(
        String(headerId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a processRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createProcessRule(
    token: string,
    orgCode: string,
    body?: ProcessRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ProcessRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createProcessRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createProcessRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.post<ProcessRule>(
      `${this.basePath}/rules/processRules`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a processRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   */
  public deleteProcessRule(
    token: string,
    orgCode: string,
    rulesId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteProcessRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteProcessRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling deleteProcessRule.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.delete<any>(
      `${this.basePath}/rules/processRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a processRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param mapLevel
   */
  public getProcessRule(
    token: string,
    orgCode: string,
    rulesId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ProcessRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getProcessRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getProcessRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling getProcessRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.get<ProcessRule>(
      `${this.basePath}/rules/processRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search processRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param request
   */
  public getProcessRules(
    token: string,
    orgCode: string,
    request: SearchProcessRulesRequest
  ): Promise<AxiosResponse<SearchProcessRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getProcessRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getProcessRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    /*if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel?.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (rulesName !== undefined && rulesName !== null) {
      queryParameters.append('rulesName', rulesName as any);
    }
    if (outProcessId !== undefined && outProcessId !== null) {
      queryParameters.append('outProcessId', outProcessId as any);
    }
    if (outServiceTypeId !== undefined && outServiceTypeId !== null) {
      queryParameters.append('outServiceTypeId', outServiceTypeId as any);
    }
    if (outStatusId !== undefined && outStatusId !== null) {
      queryParameters.append('outStatusId', outStatusId as any);
    }
    if (inProcessCode !== undefined && inProcessCode !== null) {
      queryParameters.append('inProcessCode', inProcessCode as any);
    }
    if (inServiceTypeCode !== undefined && inServiceTypeCode !== null) {
      queryParameters.append('inServiceTypeCode', inServiceTypeCode as any);
    }
    if (inStatusId !== undefined && inStatusId !== null) {
      queryParameters.append('inStatusId', inStatusId as any);
    }
    if (inChannel !== undefined && inChannel !== null) {
      queryParameters.append('inChannel', inChannel as any);
    }
    if (inWarranty !== undefined && inWarranty !== null) {
      queryParameters.append('inWarranty', inWarranty as any);
    }
    if (inManufacturerId !== undefined && inManufacturerId !== null) {
      queryParameters.append('inManufacturerId', inManufacturerId as any);
    }
    if (inNotManufacturerId !== undefined && inNotManufacturerId !== null) {
      queryParameters.append('inNotManufacturerId', inNotManufacturerId as any);
    }
    if (inCatalogCatItemId !== undefined && inCatalogCatItemId !== null) {
      queryParameters.append('inCatalogCatItemId', inCatalogCatItemId as any);
    }
    if (inCatalogModelId !== undefined && inCatalogModelId !== null) {
      queryParameters.append('inCatalogModelId', inCatalogModelId as any);
    }
    if (inCatalogProductId !== undefined && inCatalogProductId !== null) {
      queryParameters.append('inCatalogProductId', inCatalogProductId as any);
    }
    if (inCustomerType !== undefined && inCustomerType !== null) {
      queryParameters.append('inCustomerType', inCustomerType as any);
    }
    if (inCustomerSegment !== undefined && inCustomerSegment !== null) {
      queryParameters.append('inCustomerSegment', inCustomerSegment as any);
    }
    if (inCustomerNetwork !== undefined && inCustomerNetwork !== null) {
      queryParameters.append('inCustomerNetwork', inCustomerNetwork as any);
    }
    if (inCustomerId !== undefined && inCustomerId !== null) {
      queryParameters.append('inCustomerId', inCustomerId as any);
    }
    if (inEntityParentId !== undefined && inEntityParentId !== null) {
      queryParameters.append('inEntityParentId', inEntityParentId as any);
    }
    if (inEntityTypeId !== undefined && inEntityTypeId !== null) {
      queryParameters.append('inEntityTypeId', inEntityTypeId as any);
    }
    if (inEntityId !== undefined && inEntityId !== null) {
      queryParameters.append('inEntityId', inEntityId as any);
    }
    if (inLogisticProcessId !== undefined && inLogisticProcessId !== null) {
      queryParameters.append('inLogisticProcessId', inLogisticProcessId as any);
    }
    if (inScenario !== undefined && inScenario !== null) {
      queryParameters.append('inScenario', inScenario as any);
    }
    if (
      inReturnTriggerSolution !== undefined &&
      inReturnTriggerSolution !== null
    ) {
      queryParameters.append(
        'inReturnTriggerSolution',
        inReturnTriggerSolution as any
      );
    }
    if (inReturnReason !== undefined && inReturnReason !== null) {
      queryParameters.append('inReturnReason', inReturnReason as any);
    }
    if (
      outReturnTriggerSolution !== undefined &&
      outReturnTriggerSolution !== null
    ) {
      queryParameters.append(
        'outReturnTriggerSolution',
        outReturnTriggerSolution as any
      );
    }
    if (inEntityNetwork !== undefined && inEntityNetwork !== null) {
      queryParameters.append('inEntityNetwork', inEntityNetwork as any);
    }
    if (headerId !== undefined && headerId !== null) {
      queryParameters.append('headerId', headerId as any);
    }*/
    if (request) {
      for (const [key, value] of Object.entries(request)) {
        if (Array.isArray(value)) {
          for (const v of value) {
            queryParameters.append(key, v as any);
          }
        } else {
          queryParameters.append(key, value as any);
        }
      }
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.get<SearchProcessRulesResponse>(
      `${this.basePath}/rules/processRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a processRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyProcessRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: ProcessRule,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<ProcessRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyProcessRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyProcessRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling modifyProcessRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: any } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.patch<ProcessRule>(
      `${this.basePath}/rules/processRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a processRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   */
  public replaceProcessRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: ProcessRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ProcessRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceProcessRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceProcessRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling replaceProcessRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.put<ProcessRule>(
      `${this.basePath}/rules/processRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a returnReasonRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createReturnReasonRule(
    token: string,
    orgCode: string,
    body?: ReturnReasonRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ReturnReasonRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createReturnReasonRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createReturnReasonRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.post<ReturnReasonRule>(
      `${this.basePath}/rules/returnReasonRules`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a returnReasonRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   */
  public deleteReturnReasonRule(
    token: string,
    orgCode: string,
    rulesId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteReturnReasonRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteReturnReasonRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling deleteReturnReasonRule.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.delete<any>(
      `${this.basePath}/rules/returnReasonRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a returnReasonRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param mapLevel
   */
  public getReturnReasonRule(
    token: string,
    orgCode: string,
    rulesId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ReturnReasonRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getReturnReasonRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getReturnReasonRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling getReturnReasonRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.get<ReturnReasonRule>(
      `${this.basePath}/rules/returnReasonRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search returnReasonRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param ruleName
   * @param outReturnReasonCode
   * @param inChannel
   * @param inEntityType
   * @param inEntityNetwork
   * @param inEntityCountry
   * @param inEntityParentId
   * @param inEntityId
   * @param inUserProfileId
   * @param inUserId
   * @param inCatalogCatItemId
   * @param inCatalogModelId
   * @param inCatalogProductId
   * @param inManufacturerId
   * @param inNotManufacturerId
   * @param inWarranty
   * @param inWarrantyReason
   */
  public getReturnReasonRules(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    ruleName?: string,
    outReturnReasonCode?: string,
    inChannel?: string,
    inEntityType?: string,
    inEntityNetwork?: string,
    inEntityCountry?: string,
    inEntityParentId?: number,
    inEntityId?: number,
    inUserProfileId?: number,
    inUserId?: number,
    inCatalogCatItemId?: number,
    inCatalogModelId?: number,
    inCatalogProductId?: number,
    inManufacturerId?: number,
    inNotManufacturerId?: number,
    inWarranty?: string,
    inWarrantyReason?: string
  ): Promise<AxiosResponse<SearchReturnReasonRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getReturnReasonRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getReturnReasonRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel?.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (ruleName !== undefined && ruleName !== null) {
      queryParameters.append('ruleName', ruleName as any);
    }
    if (outReturnReasonCode !== undefined && outReturnReasonCode !== null) {
      queryParameters.append('outReturnReasonCode', outReturnReasonCode as any);
    }
    if (inChannel !== undefined && inChannel !== null) {
      queryParameters.append('inChannel', inChannel as any);
    }
    if (inEntityType !== undefined && inEntityType !== null) {
      queryParameters.append('inEntityType', inEntityType as any);
    }
    if (inEntityNetwork !== undefined && inEntityNetwork !== null) {
      queryParameters.append('inEntityNetwork', inEntityNetwork as any);
    }
    if (inEntityCountry !== undefined && inEntityCountry !== null) {
      queryParameters.append('inEntityCountry', inEntityCountry as any);
    }
    if (inEntityId !== undefined && inEntityId !== null) {
      queryParameters.append('inEntityId', inEntityId as any);
    }
    if (inEntityParentId !== undefined && inEntityParentId !== null) {
      queryParameters.append('inEntityParentId', inEntityParentId as any);
    }
    if (inUserProfileId !== undefined && inUserProfileId !== null) {
      queryParameters.append('inUserProfileId', inUserProfileId as any);
    }
    if (inUserId !== undefined && inUserId !== null) {
      queryParameters.append('inUserId', inUserId as any);
    }
    if (inManufacturerId !== undefined && inManufacturerId !== null) {
      queryParameters.append('inManufacturerId', inManufacturerId as any);
    }
    if (inNotManufacturerId !== undefined && inNotManufacturerId !== null) {
      queryParameters.append('inNotManufacturerId', inNotManufacturerId as any);
    }
    if (inCatalogCatItemId !== undefined && inCatalogCatItemId !== null) {
      queryParameters.append('inCatalogCatItemId', inCatalogCatItemId as any);
    }
    if (inCatalogModelId !== undefined && inCatalogModelId !== null) {
      queryParameters.append('inCatalogModelId', inCatalogModelId as any);
    }
    if (inCatalogProductId !== undefined && inCatalogProductId !== null) {
      queryParameters.append('inCatalogProductId', inCatalogProductId as any);
    }
    if (inWarranty !== undefined && inWarranty !== null) {
      queryParameters.append('inWarranty', inWarranty as any);
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.get<SearchReturnReasonRulesResponse>(
      `${this.basePath}/rules/returnReasonRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a returnReasonRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyReturnReasonRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: ReturnReasonRule,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<ReturnReasonRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyReturnReasonRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyReturnReasonRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling modifyReturnReasonRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: any } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.patch<ReturnReasonRule>(
      `${this.basePath}/rules/returnReasonRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a returnReasonRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   */
  public replaceReturnReasonRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: ReturnReasonRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ReturnReasonRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceReturnReasonRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceReturnReasonRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling replaceReturnReasonRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.put<ReturnReasonRule>(
      `${this.basePath}/rules/returnReasonRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a transitionPrealertRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createTransitionPrealertRule(
    token: string,
    orgCode: string,
    body?: TransitionPrealertRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TransitionPrealertRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createTransitionPrealertRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createTransitionPrealertRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    headers['Accept'] = this.configuration.selectHeaderAccept([
      'application/json',
    ]);

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.post<TransitionPrealertRule>(
      `${this.basePath}/rules/transitionPrealertRules`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a transitionPrealertRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param rulesId
   */
  public deleteTransitionPrealertRule(
    token: string,
    orgCode: string,
    rulesId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteTransitionPrealertRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteTransitionPrealertRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling deleteTransitionPrealertRule.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.delete<any>(
      `${this.basePath}/rules/transitionPrealertRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a transitionPrealertRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param rulesId
   * @param mapLevel
   */
  public getTransitionPrealertRule(
    token: string,
    orgCode: string,
    rulesId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TransitionPrealertRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTransitionPrealertRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTransitionPrealertRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling getTransitionPrealertRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.get<TransitionPrealertRule>(
      `${this.basePath}/rules/transitionPrealertRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search transitionPrealertRules
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param enable
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param rulesName
   * @param outActionCode
   * @param outActionReason
   * @param outPrealertEntityId
   * @param inTransitionGroup
   * @param inTransitionCode
   * @param inStatusCode
   * @param inProcessCode
   * @param inServiceType
   * @param inChannel
   * @param inManufacturerId
   * @param inDaysLastRepair
   * @param inLogisticEntityCode
   * @param inBuyerCode
   * @param inQuoteType
   * @param inOptionType
   * @param inTemplateCode
   * @param inTemplateGroup
   * @param inCommunicationType
   * @param outObjectType
   */
  public getTransitionPrealertRules(
    token: string,
    orgCode: string,
    enable?: boolean,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    rulesName?: string,
    outActionCode?: string,
    outActionReason?: string,
    outPrealertEntityId?: number,
    inTransitionGroup?: string,
    inTransitionCode?: string,
    inStatusCode?: string,
    inProcessCode?: string,
    inServiceType?: string,
    inChannel?: string,
    inManufacturerId?: number,
    inDaysLastRepair?: number,
    inLogisticEntityCode?: string,
    inBuyerCode?: string,
    inQuoteType?: string,
    inOptionType?: string,
    inTemplateCode?: string,
    inTemplateGroup?: string,
    inCommunicationType?: string,
    outObjectType?: string
  ): Promise<AxiosResponse<SearchTransitionPrealertRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTransitionPrealertRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTransitionPrealertRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (rulesName !== undefined && rulesName !== null) {
      queryParameters.append('rulesName', rulesName as any);
    }
    if (outActionCode !== undefined && outActionCode !== null) {
      queryParameters.append('outActionCode', outActionCode as any);
    }
    if (outActionReason !== undefined && outActionReason !== null) {
      queryParameters.append('outActionReason', outActionReason as any);
    }
    if (outPrealertEntityId !== undefined && outPrealertEntityId !== null) {
      queryParameters.append('outPrealertEntityId', outPrealertEntityId as any);
    }
    if (inTransitionCode !== undefined && inTransitionCode !== null) {
      queryParameters.append('inTransitionCode', inTransitionCode as any);
    }
    if (inTransitionGroup !== undefined && inTransitionGroup !== null) {
      queryParameters.append('inTransitionGroup', inTransitionGroup as any);
    }
    if (inStatusCode !== undefined && inStatusCode !== null) {
      queryParameters.append('inStatusCode', inStatusCode as any);
    }
    if (inProcessCode !== undefined && inProcessCode !== null) {
      queryParameters.append('inProcessCode', inProcessCode as any);
    }
    if (inServiceType !== undefined && inServiceType !== null) {
      queryParameters.append('inServiceType', inServiceType as any);
    }
    if (inChannel !== undefined && inChannel !== null) {
      queryParameters.append('inChannel', inChannel as any);
    }
    if (inManufacturerId !== undefined && inManufacturerId !== null) {
      queryParameters.append('inManufacturerId', inManufacturerId as any);
    }
    if (inDaysLastRepair !== undefined && inDaysLastRepair !== null) {
      queryParameters.append('inDaysLastRepair', inDaysLastRepair as any);
    }
    if (inLogisticEntityCode !== undefined && inLogisticEntityCode !== null) {
      queryParameters.append(
        'inLogisticEntityCode',
        inLogisticEntityCode as any
      );
    }
    if (inBuyerCode !== undefined && inBuyerCode !== null) {
      queryParameters.append('inBuyerCode', inBuyerCode as any);
    }
    if (inQuoteType !== undefined && inQuoteType !== null) {
      queryParameters.append('inQuoteType', inQuoteType as any);
    }
    if (inOptionType !== undefined && inOptionType !== null) {
      queryParameters.append('inOptionType', inOptionType as any);
    }
    if (inTemplateCode !== undefined && inTemplateCode !== null) {
      queryParameters.append('inTemplateCode', inTemplateCode as any);
    }
    if (inTemplateGroup !== undefined && inTemplateGroup !== null) {
      queryParameters.append('inTemplateGroup', inTemplateGroup as any);
    }
    if (inCommunicationType !== undefined && inCommunicationType !== null) {
      queryParameters.append('inCommunicationType', inCommunicationType as any);
    }
    if (outObjectType !== undefined && outObjectType !== null) {
      queryParameters.append('outObjectType', outObjectType as any);
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    headers['Accept'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.get<SearchTransitionPrealertRulesResponse>(
      `${this.basePath}/rules/transitionPrealertRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a transitionPrealertRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyTransitionPrealertRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: TransitionPrealertRule,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<TransitionPrealertRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyTransitionPrealertRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyTransitionPrealertRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling modifyTransitionPrealertRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: any } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    headers['Accept'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.patch<TransitionPrealertRule>(
      `${this.basePath}/rules/transitionPrealertRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a transitionPrealertRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   */
  public replaceTransitionPrealertRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: TransitionPrealertRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TransitionPrealertRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceTransitionPrealertRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceTransitionPrealertRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling replaceTransitionPrealertRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    headers['Accept'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.put<TransitionPrealertRule>(
      `${this.basePath}/rules/transitionPrealertRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a templateRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createTemplateRule(
    token: string,
    orgCode: string,
    body?: TemplateRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TemplateRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createTemplateRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createTemplateRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<TemplateRule>(
      `${this.basePath}/rules/templateRules`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a templateRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   */
  public deleteTemplateRule(
    token: string,
    orgCode: string,
    rulesId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteTemplateRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteTemplateRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling deleteTemplateRule.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/rules/templateRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a templateRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param mapLevel
   */
  public getTemplateRule(
    token: string,
    orgCode: string,
    rulesId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TemplateRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTemplateRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTemplateRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling getTemplateRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<TemplateRule>(
      `${this.basePath}/rules/templateRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search templateRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param request
   */
  public getTemplateRules(
    token: string,
    orgCode: string,
    request: SearchTemplateRulesRequest
  ): Promise<AxiosResponse<SearchTemplateRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTemplateRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTemplateRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    /*if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (rulesName !== undefined && rulesName !== null) {
      queryParameters.append('rulesName', rulesName as any);
    }
    if (outTemplateCode !== undefined && outTemplateCode !== null) {
      queryParameters.append('outTemplateCode', outTemplateCode as any);
    }
    if (outTemplateGroup !== undefined && outTemplateGroup !== null) {
      queryParameters.append('outTemplateGroup', outTemplateGroup as any);
    }
    if (inStatusId !== undefined && inStatusId !== null) {
      queryParameters.append('inStatusId', inStatusId as any);
    }
    if (inStatusDelay !== undefined && inStatusDelay !== null) {
      queryParameters.append('inStatusDelay', inStatusDelay as any);
    }
    if (inProcess !== undefined && inProcess !== null) {
      queryParameters.append('inProcess', inProcess as any);
    }
    if (inServiceType !== undefined && inServiceType !== null) {
      queryParameters.append('inServiceType', inServiceType as any);
    }
    if (inChannel !== undefined && inChannel !== null) {
      queryParameters.append('inChannel', inChannel as any);
    }
    if (inWarrantyCode !== undefined && inWarrantyCode !== null) {
      queryParameters.append('inWarrantyCode', inWarrantyCode as any);
    }
    if (inCustomerNetwork !== undefined && inCustomerNetwork !== null) {
      queryParameters.append('inCustomerNetwork', inCustomerNetwork as any);
    }
    if (inManufacturerId !== undefined && inManufacturerId !== null) {
      queryParameters.append('inManufacturerId', inManufacturerId as any);
    }
    if (inNotManufacturerId !== undefined && inNotManufacturerId !== null) {
      queryParameters.append('inNotManufacturerId', inNotManufacturerId as any);
    }
    if (inCustomerLocale !== undefined && inCustomerLocale !== null) {
      queryParameters.append('inCustomerLocale', inCustomerLocale as any);
    }
    if (inCustomerType !== undefined && inCustomerType !== null) {
      queryParameters.append('inCustomerType', inCustomerType as any);
    }
    if (inCustomValue !== undefined && inCustomValue !== null) {
      queryParameters.append('inCustomValue', inCustomValue as any);
    }
    if (inCustomValue2 !== undefined && inCustomValue2 !== null) {
      queryParameters.append('inCustomValue2', inCustomValue2 as any);
    }
    if (inCustomValue3 !== undefined && inCustomValue3 !== null) {
      queryParameters.append('inCustomValue3', inCustomValue3 as any);
    }
    if (inCustomValue4 !== undefined && inCustomValue4 !== null) {
      queryParameters.append('inCustomValue4', inCustomValue4 as any);
    }
    if (inCustomValue5 !== undefined && inCustomValue5 !== null) {
      queryParameters.append('inCustomValue5', inCustomValue5 as any);
    }
    if (inEntityType !== undefined && inEntityType !== null) {
      queryParameters.append('inEntityType', inEntityType as any);
    }
    if (inNotEntityType !== undefined && inNotEntityType !== null) {
      queryParameters.append('inNotEntityType', inNotEntityType as any);
    }
    if (inTemplateMethodCode !== undefined && inTemplateMethodCode !== null) {
      queryParameters.append(
        'inTemplateMethodCode',
        inTemplateMethodCode as any
      );
    }*/
    if (request) {
      for (const [key, value] of Object.entries(request)) {
        if (Array.isArray(value)) {
          for (const v of value) {
            queryParameters.append(key, v as any);
          }
        } else {
          queryParameters.append(key, value as any);
        }
      }
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchTemplateRulesResponse>(
      `${this.basePath}/rules/templateRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a templateRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyTemplateRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: TemplateRule,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<TemplateRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyTemplateRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyTemplateRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling modifyTemplateRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<TemplateRule>(
      `${this.basePath}/rules/templateRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a templateRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   */
  public replaceTemplateRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: TemplateRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TemplateRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceTemplateRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceTemplateRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling replaceTemplateRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<TemplateRule>(
      `${this.basePath}/rules/templateRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a warrantyRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createWarrantyRule(
    token: string,
    orgCode: string,
    body?: WarrantyRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<WarrantyRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createWarrantyRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createWarrantyRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.post<WarrantyRule>(
      `${this.basePath}/rules/warrantyRules`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a warrantyRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param rulesId
   */
  public deleteWarrantyRule(
    token: string,
    orgCode: string,
    rulesId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteWarrantyRule.'
      );
    }

    if (!orgCode) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteWarrantyRule.'
      );
    }

    if (rulesId) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling deleteWarrantyRule.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.delete<any>(
      `${this.basePath}/rules/warrantyRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a warrantyRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param rulesId
   * @param mapLevel
   */
  public getWarrantyRule(
    token: string,
    orgCode: string,
    rulesId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<WarrantyRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getWarrantyRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getWarrantyRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling getWarrantyRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.get<WarrantyRule>(
      `${this.basePath}/rules/warrantyRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search warrantyRules
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param request
   */
  public getWarrantyRules(
    token: string,
    orgCode: string,
    request?: SearchWarrantyRulesRequest
  ): Promise<AxiosResponse<SearchWarrantyRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getWarrantyRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getWarrantyRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    /*if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount ', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset ', limitOffset as any);
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable ', enable as any);
    }
    if (rulesName !== undefined && rulesName !== null) {
      queryParameters.append('rulesName ', rulesName as any);
    }
    if (inRulesDateBegin !== undefined && inRulesDateBegin !== null) {
      queryParameters.append('inRulesDateBegin ', inRulesDateBegin as any);
    }
    if (inRulesDateEnd !== undefined && inRulesDateEnd !== null) {
      queryParameters.append('inRulesDateEnd ', inRulesDateEnd as any);
    }
    if (outWarrantyId !== undefined && outWarrantyId !== null) {
      queryParameters.append('outWarrantyId ', outWarrantyId as any);
    }
    if (inWarrantyCode !== undefined && inWarrantyCode !== null) {
      queryParameters.append('inWarrantyCode ', inWarrantyCode as any);
    }
    if (inServiceType !== undefined && inServiceType !== null) {
      queryParameters.append('inServiceType ', inServiceType as any);
    }
    if (inDelaySinceRepair !== undefined && inDelaySinceRepair !== null) {
      queryParameters.append('inDelaySinceRepair ', inDelaySinceRepair as any);
    }
    if (inDelaySincePurchase !== undefined && inDelaySincePurchase !== null) {
      queryParameters.append(
        'inDelaySincePurchase ',
        inDelaySincePurchase as any
      );
    }
    if (inDelaySinceBuild !== undefined && inDelaySinceBuild !== null) {
      queryParameters.append('inDelaySinceBuild ', inDelaySinceBuild as any);
    }
    if (inPurchaseDateBegin !== undefined && inPurchaseDateBegin !== null) {
      queryParameters.append(
        'inPurchaseDateBegin ',
        inPurchaseDateBegin as any
      );
    }
    if (inPurchaseProof !== undefined && inPurchaseProof !== null) {
      queryParameters.append('inPurchaseProof ', inPurchaseProof as any);
    }
    if (inManufacturerId !== undefined && inManufacturerId !== null) {
      queryParameters.append('inManufacturerId ', inManufacturerId as any);
    }
    if (inNotManufacturerId !== undefined && inNotManufacturerId !== null) {
      queryParameters.append(
        'inNotManufacturerId ',
        inNotManufacturerId as any
      );
    }
    if (inCatalogCatItemId !== undefined && inCatalogCatItemId !== null) {
      queryParameters.append('inCatalogCatItemId ', inCatalogCatItemId as any);
    }
    if (inCatalogModelId !== undefined && inCatalogModelId !== null) {
      queryParameters.append('inCatalogModelId ', inCatalogModelId as any);
    }
    if (inCatalogProductId !== undefined && inCatalogProductId !== null) {
      queryParameters.append('inCatalogProductId ', inCatalogProductId as any);
    }
    if (inCustomerType !== undefined && inCustomerType !== null) {
      queryParameters.append('inCustomerType ', inCustomerType as any);
    }
    if (inCustomerSegment !== undefined && inCustomerSegment !== null) {
      queryParameters.append('inCustomerSegment ', inCustomerSegment as any);
    }
    if (inCustomerNetwork !== undefined && inCustomerNetwork !== null) {
      queryParameters.append('inCustomerNetwork ', inCustomerNetwork as any);
    }
    if (inCustomerCountry !== undefined && inCustomerCountry !== null) {
      queryParameters.append('inCustomerCountry ', inCustomerCountry as any);
    }
    if (inCustomerId !== undefined && inCustomerId !== null) {
      queryParameters.append('inCustomerId ', inCustomerId as any);
    }
    if (inEntityParentId !== undefined && inEntityParentId !== null) {
      queryParameters.append('inEntityParentId ', inEntityParentId as any);
    }
    if (inEntityTypeId !== undefined && inEntityTypeId !== null) {
      queryParameters.append('inEntityTypeId ', inEntityTypeId as any);
    }
    if (inEntityId !== undefined && inEntityId !== null) {
      queryParameters.append('inEntityId ', inEntityId as any);
    }
    if (inProductImei !== undefined && inProductImei !== null) {
      queryParameters.append('inProductImei ', inProductImei as any);
    }
    if (inProductSerial !== undefined && inProductSerial !== null) {
      queryParameters.append('inProductSerial ', inProductSerial as any);
    }
    if (inProductBroken !== undefined && inProductBroken !== null) {
      queryParameters.append('inProductBroken ', inProductBroken as any);
    }
    if (inProductOxydation !== undefined && inProductOxydation !== null) {
      queryParameters.append('inProductOxydation ', inProductOxydation as any);
    }
    if (inProductSwitchOn !== undefined && inProductSwitchOn !== null) {
      queryParameters.append('inProductSwitchOn ', inProductSwitchOn as any);
    }
    if (inProductCosmetic !== undefined && inProductCosmetic !== null) {
      queryParameters.append('inProductCosmetic ', inProductCosmetic as any);
    }
    if (
      inDelaySinceOowShippedOut !== undefined &&
      inDelaySinceOowShippedOut !== null
    ) {
      queryParameters.append(
        'inDelaySinceOowShippedOut ',
        inDelaySinceOowShippedOut as any
      );
    }
    if (
      inStartDateRcShippedOut !== undefined &&
      inStartDateRcShippedOut !== null
    ) {
      queryParameters.append(
        'inStartDateRcShippedOut ',
        inStartDateRcShippedOut as any
      );
    }
    if (inGrading !== undefined && inGrading !== null) {
      queryParameters.append('inGrading ', inGrading as any);
    }
    if (inIncidentAvailable !== undefined && inIncidentAvailable !== null) {
      queryParameters.append(
        'inIncidentAvailable ',
        inIncidentAvailable as any
      );
    }
    if (inWarrantyExtension !== undefined && inWarrantyExtension !== null) {
      queryParameters.append(
        'inWarrantyExtension ',
        inWarrantyExtension as any
      );
    }
    if (inWarrantyProofCode !== undefined && inWarrantyProofCode !== null) {
      queryParameters.append(
        'inWarrantyProofCode ',
        inWarrantyProofCode as any
      );
    }
    if (inWarrantyInput !== undefined && inWarrantyInput !== null) {
      queryParameters.append('inWarrantyInput ', inWarrantyInput as any);
    }
    if (inWarrantyMethod !== undefined && inWarrantyMethod !== null) {
      queryParameters.append('inWarrantyMethod ', inWarrantyMethod as any);
    }
    if (outWarrantyReasonCode !== undefined && outWarrantyReasonCode !== null) {
      queryParameters.append(
        'outWarrantyReasonCode ',
        outWarrantyReasonCode as any
      );
    }*/
    if (request) {
      for (const [key, value] of Object.entries(request)) {
        if (Array.isArray(value)) {
          for (const v of value) {
            queryParameters.append(key, v as any);
          }
        } else {
          queryParameters.append(key, value as any);
        }
      }
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.get<SearchWarrantyRulesResponse>(
      `${this.basePath}/rules/warrantyRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a warrantyRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyWarrantyRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: WarrantyRule,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<WarrantyRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyWarrantyRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyWarrantyRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling modifyWarrantyRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: any } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    headers['Accept'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.patch<WarrantyRule>(
      `${this.basePath}/rules/warrantyRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a warrantyRule
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   */
  public replaceWarrantyRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: WarrantyRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<WarrantyRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceWarrantyRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceWarrantyRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling replaceWarrantyRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    headers['Accept'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.put<WarrantyRule>(
      `${this.basePath}/rules/warrantyRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a quoteOptionRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createQuoteOptionRule(
    token: string,
    orgCode: string,
    body?: QuoteOptionRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<QuoteOptionRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createQuoteOptionRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createQuoteOptionRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<QuoteOptionRule>(
      `${this.basePath}/rules/quoteOptionRules`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a quoteOptionRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   */
  public deleteQuoteOptionRule(
    token: string,
    orgCode: string,
    rulesId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteQuoteOptionRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteQuoteOptionRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling deleteQuoteOptionRule.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/rules/quoteOptionRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a quoteOptionRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param mapLevel
   */
  public getQuoteOptionRule(
    token: string,
    orgCode: string,
    rulesId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<QuoteOptionRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getQuoteOptionRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getQuoteOptionRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling getQuoteOptionRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<QuoteOptionRule>(
      `${this.basePath}/rules/quoteOptionRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search quoteOptionRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param request
   */
  public getQuoteOptionRules(
    token: string,
    orgCode: string,
    request?: SearchQuoteOptionRulesRequest
  ): Promise<AxiosResponse<SearchQuoteOptionRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getQuoteOptionRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getQuoteOptionRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    /*if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }*/
    if (request) {
      for (const [key, value] of Object.entries(request)) {
        if (Array.isArray(value)) {
          for (const v of value) {
            queryParameters.append(key, v as any);
          }
        } else {
          queryParameters.append(key, value as any);
        }
      }
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchQuoteOptionRulesResponse>(
      `${this.basePath}/rules/quoteOptionRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a quoteOptionRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyQuoteOptionRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: QuoteOptionRule,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<QuoteOptionRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyQuoteOptionRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyQuoteOptionRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling modifyQuoteOptionRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<QuoteOptionRule>(
      `${this.basePath}/rules/quoteOptionRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a quoteOptionRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   */
  public replaceQuoteOptionRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: QuoteOptionRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<QuoteOptionRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceQuoteOptionRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceQuoteOptionRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling replaceQuoteOptionRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<QuoteOptionRule>(
      `${this.basePath}/rules/quoteOptionRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a logisticProcessRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createLogisticProcessRule(
    token: string,
    orgCode: string,
    body?: LogisticProcessRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticProcessRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createLogisticProcessRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createLogisticProcessRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<LogisticProcessRule>(
      `${this.basePath}/rules/logisticProcessRules`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a logisticProcessRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   */
  public deleteLogisticProcessRule(
    token: string,
    orgCode: string,
    rulesId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteLogisticProcessRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteLogisticProcessRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling deleteLogisticProcessRule.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/rules/logisticProcessRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a logisticProcessRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param mapLevel
   */
  public getLogisticProcessRule(
    token: string,
    orgCode: string,
    rulesId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticProcessRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getLogisticProcessRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getLogisticProcessRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling getLogisticProcessRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<LogisticProcessRule>(
      `${this.basePath}/rules/logisticProcessRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search logisticProcessRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param rulesName
   * @param inProcess
   * @param inServiceType
   * @param inChannel
   * @param inLogisticScenario
   * @param inLogisticLeg
   * @param inBuyerEntityId
   * @param inRequestCountry
   * @param inRequestEntityType
   * @param inRequestEntityNetwork
   * @param inRequestEntityParentId
   * @param inRequestEntityId
   * @param inCollectionStateName
   * @param inCollectionZipcode
   * @param inCollectionCountry
   * @param inCollectionEntityType
   * @param inCollectionEntityNetwork
   * @param inCollectionEntityParentId
   * @param inCollectionEntityId
   * @param inDeliveryStateName
   * @param inDeliveryZipcode
   * @param inDeliveryCountry
   * @param inDeliveryEntityType
   * @param inDeliveryEntityNetwork
   * @param inDeliveryEntityParentId
   * @param inDeliveryEntityId
   * @param inCustomerId
   * @param inCustomerType
   * @param inCustomerSegment
   * @param inCustomerNetwork
   * @param inProductWarrantyCode
   * @param inProductGradeCode
   * @param inCatalogCatItemId
   * @param inCatalogModelId
   * @param inCatalogProductId
   * @param inManufacturerId
   * @param inNotManufacturerId
   * @param inLogisticCatItem
   * @param headerId
   */
  public getLogisticProcessRules(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    rulesName?: string,
    inProcess?: string,
    inServiceType?: string,
    inChannel?: string,
    inLogisticScenario?: string,
    inLogisticLeg?: string,
    inBuyerEntityId?: number,
    inRequestCountry?: string,
    inRequestEntityType?: string,
    inRequestEntityNetwork?: string,
    inRequestEntityParentId?: number,
    inRequestEntityId?: number,
    inCollectionStateName?: string,
    inCollectionZipcode?: string,
    inCollectionCountry?: string,
    inCollectionEntityType?: string,
    inCollectionEntityNetwork?: string,
    inCollectionEntityParentId?: number,
    inCollectionEntityId?: number,
    inDeliveryStateName?: string,
    inDeliveryZipcode?: string,
    inDeliveryCountry?: string,
    inDeliveryEntityType?: string,
    inDeliveryEntityNetwork?: string,
    inDeliveryEntityParentId?: number,
    inDeliveryEntityId?: number,
    inCustomerId?: number,
    inCustomerType?: string,
    inCustomerSegment?: string,
    inCustomerNetwork?: string,
    inProductWarrantyCode?: string,
    inProductGradeCode?: string,
    inCatalogCatItemId?: number,
    inCatalogModelId?: number,
    inCatalogProductId?: number,
    inManufacturerId?: number,
    inNotManufacturerId?: number,
    inLogisticCatItem?: string,
    headerId?: number
  ): Promise<AxiosResponse<SearchLogisticProcessRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getLogisticProcessRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getLogisticProcessRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (rulesName !== undefined && rulesName !== null) {
      queryParameters.append('rulesName', rulesName as any);
    }
    if (inProcess !== undefined && inProcess !== null) {
      queryParameters.append('inProcess', inProcess as any);
    }
    if (inServiceType !== undefined && inServiceType !== null) {
      queryParameters.append('inServiceType', inServiceType as any);
    }
    if (inChannel !== undefined && inChannel !== null) {
      queryParameters.append('inChannel', inChannel as any);
    }
    if (inLogisticScenario !== undefined && inLogisticScenario !== null) {
      queryParameters.append('inLogisticScenario', inLogisticScenario as any);
    }
    if (inLogisticLeg !== undefined && inLogisticLeg !== null) {
      queryParameters.append('inLogisticLeg', inLogisticLeg as any);
    }
    if (inBuyerEntityId !== undefined && inBuyerEntityId !== null) {
      queryParameters.append('inBuyerEntityId', inBuyerEntityId as any);
    }
    if (inRequestCountry !== undefined && inRequestCountry !== null) {
      queryParameters.append('inRequestCountry', inRequestCountry as any);
    }
    if (inRequestEntityType !== undefined && inRequestEntityType !== null) {
      queryParameters.append('inRequestEntityType', inRequestEntityType as any);
    }
    if (
      inRequestEntityNetwork !== undefined &&
      inRequestEntityNetwork !== null
    ) {
      queryParameters.append(
        'inRequestEntityNetwork',
        inRequestEntityNetwork as any
      );
    }
    if (
      inRequestEntityParentId !== undefined &&
      inRequestEntityParentId !== null
    ) {
      queryParameters.append(
        'inRequestEntityParentId',
        inRequestEntityParentId as any
      );
    }
    if (inRequestEntityId !== undefined && inRequestEntityId !== null) {
      queryParameters.append('inRequestEntityId', inRequestEntityId as any);
    }
    if (inCollectionStateName !== undefined && inCollectionStateName !== null) {
      queryParameters.append(
        'inCollectionStateName',
        inCollectionStateName as any
      );
    }
    if (inCollectionZipcode !== undefined && inCollectionZipcode !== null) {
      queryParameters.append('inCollectionZipcode', inCollectionZipcode as any);
    }
    if (inCollectionCountry !== undefined && inCollectionCountry !== null) {
      queryParameters.append('inCollectionCountry', inCollectionCountry as any);
    }
    if (
      inCollectionEntityType !== undefined &&
      inCollectionEntityType !== null
    ) {
      queryParameters.append(
        'inCollectionEntityType',
        inCollectionEntityType as any
      );
    }
    if (
      inCollectionEntityNetwork !== undefined &&
      inCollectionEntityNetwork !== null
    ) {
      queryParameters.append(
        'inCollectionEntityNetwork',
        inCollectionEntityNetwork as any
      );
    }
    if (
      inCollectionEntityParentId !== undefined &&
      inCollectionEntityParentId !== null
    ) {
      queryParameters.append(
        'inCollectionEntityParentId',
        inCollectionEntityParentId as any
      );
    }
    if (inCollectionEntityId !== undefined && inCollectionEntityId !== null) {
      queryParameters.append(
        'inCollectionEntityId',
        inCollectionEntityId as any
      );
    }
    if (inDeliveryStateName !== undefined && inDeliveryStateName !== null) {
      queryParameters.append('inDeliveryStateName', inDeliveryStateName as any);
    }
    if (inDeliveryZipcode !== undefined && inDeliveryZipcode !== null) {
      queryParameters.append('inDeliveryZipcode', inDeliveryZipcode as any);
    }
    if (inDeliveryCountry !== undefined && inDeliveryCountry !== null) {
      queryParameters.append('inDeliveryCountry', inDeliveryCountry as any);
    }
    if (inDeliveryEntityType !== undefined && inDeliveryEntityType !== null) {
      queryParameters.append(
        'inDeliveryEntityType',
        inDeliveryEntityType as any
      );
    }
    if (
      inDeliveryEntityNetwork !== undefined &&
      inDeliveryEntityNetwork !== null
    ) {
      queryParameters.append(
        'inDeliveryEntityNetwork',
        inDeliveryEntityNetwork as any
      );
    }
    if (
      inDeliveryEntityParentId !== undefined &&
      inDeliveryEntityParentId !== null
    ) {
      queryParameters.append(
        'inDeliveryEntityParentId',
        inDeliveryEntityParentId as any
      );
    }
    if (inDeliveryEntityId !== undefined && inDeliveryEntityId !== null) {
      queryParameters.append('inDeliveryEntityId', inDeliveryEntityId as any);
    }
    if (inCustomerId !== undefined && inCustomerId !== null) {
      queryParameters.append('inCustomerId', inCustomerId as any);
    }
    if (inCustomerType !== undefined && inCustomerType !== null) {
      queryParameters.append('inCustomerType', inCustomerType as any);
    }
    if (inCustomerSegment !== undefined && inCustomerSegment !== null) {
      queryParameters.append('inCustomerSegment', inCustomerSegment as any);
    }
    if (inCustomerNetwork !== undefined && inCustomerNetwork !== null) {
      queryParameters.append('inCustomerNetwork', inCustomerNetwork as any);
    }
    if (inProductWarrantyCode !== undefined && inProductWarrantyCode !== null) {
      queryParameters.append(
        'inProductWarrantyCode',
        inProductWarrantyCode as any
      );
    }
    if (inProductGradeCode !== undefined && inProductGradeCode !== null) {
      queryParameters.append('inProductGradeCode', inProductGradeCode as any);
    }
    if (inCatalogCatItemId !== undefined && inCatalogCatItemId !== null) {
      queryParameters.append('inCatalogCatItemId', inCatalogCatItemId as any);
    }
    if (inCatalogModelId !== undefined && inCatalogModelId !== null) {
      queryParameters.append('inCatalogModelId', inCatalogModelId as any);
    }
    if (inCatalogProductId !== undefined && inCatalogProductId !== null) {
      queryParameters.append('inCatalogProductId', inCatalogProductId as any);
    }
    if (inManufacturerId !== undefined && inManufacturerId !== null) {
      queryParameters.append('inManufacturerId', inManufacturerId as any);
    }
    if (inNotManufacturerId !== undefined && inNotManufacturerId !== null) {
      queryParameters.append('inNotManufacturerId', inNotManufacturerId as any);
    }
    if (inLogisticCatItem !== undefined && inLogisticCatItem !== null) {
      queryParameters.append('inLogisticCatItem', inLogisticCatItem as any);
    }
    if (headerId !== undefined && headerId !== null) {
      queryParameters.append('headerId', headerId as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchLogisticProcessRulesResponse>(
      `${this.basePath}/rules/logisticProcessRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a logisticProcessRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyLogisticProcessRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: LogisticProcessRule,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<LogisticProcessRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyLogisticProcessRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyLogisticProcessRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling modifyLogisticProcessRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<LogisticProcessRule>(
      `${this.basePath}/rules/logisticProcessRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a logisticProcessRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   */
  public replaceLogisticProcessRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: LogisticProcessRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticProcessRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceLogisticProcessRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceLogisticProcessRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling replaceLogisticProcessRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<LogisticProcessRule>(
      `${this.basePath}/rules/logisticProcessRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a logisticDeliveryRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createLogisticDeliveryRule(
    token: string,
    orgCode: string,
    body?: LogisticDeliveryRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticDeliveryRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createLogisticDeliveryRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createLogisticDeliveryRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<LogisticDeliveryRule>(
      `${this.basePath}/rules/logisticDeliveryRules`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a logisticDeliveryRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   */
  public deleteLogisticDeliveryRule(
    token: string,
    orgCode: string,
    rulesId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteLogisticDeliveryRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteLogisticDeliveryRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling deleteLogisticDeliveryRule.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/rules/logisticDeliveryRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a logisticDeliveryRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param mapLevel
   */
  public getLogisticDeliveryRule(
    token: string,
    orgCode: string,
    rulesId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticDeliveryRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getLogisticDeliveryRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getLogisticDeliveryRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling getLogisticDeliveryRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<LogisticDeliveryRule>(
      `${this.basePath}/rules/logisticDeliveryRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search logisticDeliveryRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param request
   */
  public getLogisticDeliveryRules(
    token: string,
    orgCode: string,
    request: SearchLogisticDeliveryRulesRequest
  ): Promise<AxiosResponse<SearchLogisticDeliveryRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getLogisticDeliveryRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getLogisticDeliveryRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    /*if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (rulesName !== undefined && rulesName !== null) {
      queryParameters.append('rulesName', rulesName as any);
    }
    if (inProcess !== undefined && inProcess !== null) {
      queryParameters.append('inProcess', inProcess as any);
    }
    if (inServiceType !== undefined && inServiceType !== null) {
      queryParameters.append('inServiceType', inServiceType as any);
    }
    if (inChannel !== undefined && inChannel !== null) {
      queryParameters.append('inChannel', inChannel as any);
    }
    if (inLogisticScenario !== undefined && inLogisticScenario !== null) {
      queryParameters.append('inLogisticScenario', inLogisticScenario as any);
    }
    if (inBuyerEntityId !== undefined && inBuyerEntityId !== null) {
      queryParameters.append('inBuyerEntityId', inBuyerEntityId as any);
    }
    if (inBuyerEntityCode !== undefined && inBuyerEntityCode !== null) {
      queryParameters.append('inBuyerEntityCode', inBuyerEntityCode as any);
    }
    if (inRequestCountry !== undefined && inRequestCountry !== null) {
      queryParameters.append('inRequestCountry', inRequestCountry as any);
    }
    if (inRequestEntityType !== undefined && inRequestEntityType !== null) {
      queryParameters.append('inRequestEntityType', inRequestEntityType as any);
    }
    if (
      inRequestEntityNetwork !== undefined &&
      inRequestEntityNetwork !== null
    ) {
      queryParameters.append(
        'inRequestEntityNetwork',
        inRequestEntityNetwork as any
      );
    }
    if (
      inRequestEntityParentId !== undefined &&
      inRequestEntityParentId !== null
    ) {
      queryParameters.append(
        'inRequestEntityParentId',
        inRequestEntityParentId as any
      );
    }
    if (inRequestEntityId !== undefined && inRequestEntityId !== null) {
      queryParameters.append('inRequestEntityId', inRequestEntityId as any);
    }
    if (inCollectionStateName !== undefined && inCollectionStateName !== null) {
      queryParameters.append(
        'inCollectionStateName',
        inCollectionStateName as any
      );
    }
    if (inCollectionZipcode !== undefined && inCollectionZipcode !== null) {
      queryParameters.append('inCollectionZipcode', inCollectionZipcode as any);
    }
    if (inCollectionCountry !== undefined && inCollectionCountry !== null) {
      queryParameters.append('inCollectionCountry', inCollectionCountry as any);
    }
    if (
      inCollectionEntityType !== undefined &&
      inCollectionEntityType !== null
    ) {
      queryParameters.append(
        'inCollectionEntityType',
        inCollectionEntityType as any
      );
    }
    if (
      inCollectionEntityNetwork !== undefined &&
      inCollectionEntityNetwork !== null
    ) {
      queryParameters.append(
        'inCollectionEntityNetwork',
        inCollectionEntityNetwork as any
      );
    }
    if (
      inCollectionEntityParentId !== undefined &&
      inCollectionEntityParentId !== null
    ) {
      queryParameters.append(
        'inCollectionEntityParentId',
        inCollectionEntityParentId as any
      );
    }
    if (inCollectionEntityId !== undefined && inCollectionEntityId !== null) {
      queryParameters.append(
        'inCollectionEntityId',
        inCollectionEntityId as any
      );
    }
    if (inCustomerId !== undefined && inCustomerId !== null) {
      queryParameters.append('inCustomerId', inCustomerId as any);
    }
    if (inCustomerType !== undefined && inCustomerType !== null) {
      queryParameters.append('inCustomerType', inCustomerType as any);
    }
    if (inCustomerSegment !== undefined && inCustomerSegment !== null) {
      queryParameters.append('inCustomerSegment', inCustomerSegment as any);
    }
    if (inCustomerNetwork !== undefined && inCustomerNetwork !== null) {
      queryParameters.append('inCustomerNetwork', inCustomerNetwork as any);
    }
    if (inProductWarrantyCode !== undefined && inProductWarrantyCode !== null) {
      queryParameters.append(
        'inProductWarrantyCode',
        inProductWarrantyCode as any
      );
    }
    if (inProductGradeCode !== undefined && inProductGradeCode !== null) {
      queryParameters.append('inProductGradeCode', inProductGradeCode as any);
    }
    if (inCatalogCatItemId !== undefined && inCatalogCatItemId !== null) {
      queryParameters.append('inCatalogCatItemId', inCatalogCatItemId as any);
    }
    if (inCatalogModelId !== undefined && inCatalogModelId !== null) {
      queryParameters.append('inCatalogModelId', inCatalogModelId as any);
    }
    if (inCatalogProductId !== undefined && inCatalogProductId !== null) {
      queryParameters.append('inCatalogProductId', inCatalogProductId as any);
    }
    if (inManufacturerId !== undefined && inManufacturerId !== null) {
      queryParameters.append('inManufacturerId', inManufacturerId as any);
    }
    if (inNotManufacturerId !== undefined && inNotManufacturerId !== null) {
      queryParameters.append('inNotManufacturerId', inNotManufacturerId as any);
    }
    if (outDeliveryEntityId !== undefined && outDeliveryEntityId !== null) {
      queryParameters.append('outDeliveryEntityId', outDeliveryEntityId as any);
    }
    if (outDeliveryAddressId !== undefined && outDeliveryAddressId !== null) {
      queryParameters.append(
        'outDeliveryAddressId',
        outDeliveryAddressId as any
      );
    }
    if (outLogisticLeg !== undefined && outLogisticLeg !== null) {
      queryParameters.append('outLogisticLeg', outLogisticLeg as any);
    }
    if (orderByPriority !== undefined && orderByPriority !== null) {
      queryParameters.append('orderByPriority', orderByPriority as any);
    }
    if (headerId !== undefined && headerId !== null) {
      queryParameters.append('headerId', headerId as any);
    }*/
    if (request) {
      for (const [key, value] of Object.entries(request)) {
        if (Array.isArray(value)) {
          for (const v of value) {
            queryParameters.append(key, v as any);
          }
        } else {
          queryParameters.append(key, value as any);
        }
      }
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchLogisticDeliveryRulesResponse>(
      `${this.basePath}/rules/logisticDeliveryRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a logisticDeliveryRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyLogisticDeliveryRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: LogisticDeliveryRule,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<LogisticDeliveryRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyLogisticDeliveryRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyLogisticDeliveryRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling modifyLogisticDeliveryRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<LogisticDeliveryRule>(
      `${this.basePath}/rules/logisticDeliveryRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a logisticDeliveryRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   */
  public replaceLogisticDeliveryRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: LogisticDeliveryRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticDeliveryRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceLogisticDeliveryRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceLogisticDeliveryRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling replaceLogisticDeliveryRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<LogisticDeliveryRule>(
      `${this.basePath}/rules/logisticDeliveryRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a logisticConfigRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createLogisticConfigRule(
    token: string,
    orgCode: string,
    body?: LogisticConfigRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticConfigRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createLogisticConfigRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createLogisticConfigRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<LogisticConfigRule>(
      `${this.basePath}/rules/logisticConfigRules`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a logisticConfigRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param processId
   * @param rulesId
   */
  public deleteLogisticConfigRule(
    token: string,
    orgCode: string,
    processId: number,
    rulesId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteLogisticConfigRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteLogisticConfigRule.'
      );
    }

    if (processId === null || processId === undefined) {
      throw new Error(
        'Required parameter processId was null or undefined when calling deleteLogisticConfigRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling deleteLogisticConfigRule.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/rules/logisticConfigRules/${encodeURIComponent(
        String(processId)
      )}/${encodeURIComponent(String(rulesId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a logisticConfigRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param processId
   * @param rulesId
   * @param mapLevel
   */
  public getLogisticConfigRule(
    token: string,
    orgCode: string,
    processId: number,
    rulesId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticConfigRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getLogisticConfigRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getLogisticConfigRule.'
      );
    }

    if (processId === null || processId === undefined) {
      throw new Error(
        'Required parameter processId was null or undefined when calling getLogisticConfigRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling getLogisticConfigRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<LogisticConfigRule>(
      `${this.basePath}/rules/logisticConfigRules/${encodeURIComponent(
        String(processId)
      )}/${encodeURIComponent(String(rulesId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search logisticConfigRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param configName
   * @param logisticProcessId
   * @param outConfigId
   * @param inProcess
   * @param inServiceType
   * @param inChannel
   * @param inBuyerEntityId
   * @param inRequestCountry
   * @param inRequestEntityType
   * @param inRequestEntityNetwork
   * @param inRequestEntityParentId
   * @param inRequestEntityId
   * @param inCollectionStateName
   * @param inCollectionZipcode
   * @param inCollectionCountry
   * @param inCollectionEntityType
   * @param inCollectionEntityNetwork
   * @param inCollectionEntityParentId
   * @param inCollectionEntityId
   * @param inDeliveryStateName
   * @param inDeliveryZipcode
   * @param inDeliveryCountry
   * @param inDeliveryEntityType
   * @param inDeliveryEntityNetwork
   * @param inDeliveryEntityParentId
   * @param inDeliveryEntityId
   * @param inCustomerId
   * @param inCustomerType
   * @param inCustomerSegment
   * @param inCustomerNetwork
   * @param inProductWarrantyCode
   * @param inProductGradeCode
   * @param inCatalogCatItemId
   * @param inCatalogModelId
   * @param inCatalogProductId
   * @param inManufacturerId
   * @param inNotManufacturerId
   * @param inPickupType
   * @param inCarrier
   * @param headerId
   */
  public getLogisticConfigRules(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    configName?: string,
    logisticProcessId?: number,
    outConfigId?: number,
    inProcess?: string,
    inServiceType?: string,
    inChannel?: string,
    inBuyerEntityId?: number,
    inRequestCountry?: string,
    inRequestEntityType?: string,
    inRequestEntityNetwork?: string,
    inRequestEntityParentId?: number,
    inRequestEntityId?: number,
    inCollectionStateName?: string,
    inCollectionZipcode?: string,
    inCollectionCountry?: string,
    inCollectionEntityType?: string,
    inCollectionEntityNetwork?: string,
    inCollectionEntityParentId?: number,
    inCollectionEntityId?: number,
    inDeliveryStateName?: string,
    inDeliveryZipcode?: string,
    inDeliveryCountry?: string,
    inDeliveryEntityType?: string,
    inDeliveryEntityNetwork?: string,
    inDeliveryEntityParentId?: number,
    inDeliveryEntityId?: number,
    inCustomerId?: number,
    inCustomerType?: string,
    inCustomerSegment?: string,
    inCustomerNetwork?: string,
    inProductWarrantyCode?: string,
    inProductGradeCode?: string,
    inCatalogCatItemId?: number,
    inCatalogModelId?: number,
    inCatalogProductId?: number,
    inManufacturerId?: number,
    inNotManufacturerId?: number,
    inPickupType?: string,
    inCarrier?: string,
    headerId?: number
  ): Promise<AxiosResponse<SearchLogisticConfigRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getLogisticConfigRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getLogisticConfigRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (configName !== undefined && configName !== null) {
      queryParameters.append('configName', configName as any);
    }
    if (logisticProcessId !== undefined && logisticProcessId !== null) {
      queryParameters.append('logisticProcessId', logisticProcessId as any);
    }
    if (outConfigId !== undefined && outConfigId !== null) {
      queryParameters.append('outConfigId', outConfigId as any);
    }
    if (inProcess !== undefined && inProcess !== null) {
      queryParameters.append('inProcess', inProcess as any);
    }
    if (inServiceType !== undefined && inServiceType !== null) {
      queryParameters.append('inServiceType', inServiceType as any);
    }
    if (inChannel !== undefined && inChannel !== null) {
      queryParameters.append('inChannel', inChannel as any);
    }
    if (inBuyerEntityId !== undefined && inBuyerEntityId !== null) {
      queryParameters.append('inBuyerEntityId', inBuyerEntityId as any);
    }
    if (inRequestCountry !== undefined && inRequestCountry !== null) {
      queryParameters.append('inRequestCountry', inRequestCountry as any);
    }
    if (inRequestEntityType !== undefined && inRequestEntityType !== null) {
      queryParameters.append('inRequestEntityType', inRequestEntityType as any);
    }
    if (
      inRequestEntityNetwork !== undefined &&
      inRequestEntityNetwork !== null
    ) {
      queryParameters.append(
        'inRequestEntityNetwork',
        inRequestEntityNetwork as any
      );
    }
    if (
      inRequestEntityParentId !== undefined &&
      inRequestEntityParentId !== null
    ) {
      queryParameters.append(
        'inRequestEntityParentId',
        inRequestEntityParentId as any
      );
    }
    if (inRequestEntityId !== undefined && inRequestEntityId !== null) {
      queryParameters.append('inRequestEntityId', inRequestEntityId as any);
    }
    if (inCollectionStateName !== undefined && inCollectionStateName !== null) {
      queryParameters.append(
        'inCollectionStateName',
        inCollectionStateName as any
      );
    }
    if (inCollectionZipcode !== undefined && inCollectionZipcode !== null) {
      queryParameters.append('inCollectionZipcode', inCollectionZipcode as any);
    }
    if (inCollectionCountry !== undefined && inCollectionCountry !== null) {
      queryParameters.append('inCollectionCountry', inCollectionCountry as any);
    }
    if (
      inCollectionEntityType !== undefined &&
      inCollectionEntityType !== null
    ) {
      queryParameters.append(
        'inCollectionEntityType',
        inCollectionEntityType as any
      );
    }
    if (
      inCollectionEntityNetwork !== undefined &&
      inCollectionEntityNetwork !== null
    ) {
      queryParameters.append(
        'inCollectionEntityNetwork',
        inCollectionEntityNetwork as any
      );
    }
    if (
      inCollectionEntityParentId !== undefined &&
      inCollectionEntityParentId !== null
    ) {
      queryParameters.append(
        'inCollectionEntityParentId',
        inCollectionEntityParentId as any
      );
    }
    if (inCollectionEntityId !== undefined && inCollectionEntityId !== null) {
      queryParameters.append(
        'inCollectionEntityId',
        inCollectionEntityId as any
      );
    }
    if (inDeliveryStateName !== undefined && inDeliveryStateName !== null) {
      queryParameters.append('inDeliveryStateName', inDeliveryStateName as any);
    }
    if (inDeliveryZipcode !== undefined && inDeliveryZipcode !== null) {
      queryParameters.append('inDeliveryZipcode', inDeliveryZipcode as any);
    }
    if (inDeliveryCountry !== undefined && inDeliveryCountry !== null) {
      queryParameters.append('inDeliveryCountry', inDeliveryCountry as any);
    }
    if (inDeliveryEntityType !== undefined && inDeliveryEntityType !== null) {
      queryParameters.append(
        'inDeliveryEntityType',
        inDeliveryEntityType as any
      );
    }
    if (
      inDeliveryEntityNetwork !== undefined &&
      inDeliveryEntityNetwork !== null
    ) {
      queryParameters.append(
        'inDeliveryEntityNetwork',
        inDeliveryEntityNetwork as any
      );
    }
    if (
      inDeliveryEntityParentId !== undefined &&
      inDeliveryEntityParentId !== null
    ) {
      queryParameters.append(
        'inDeliveryEntityParentId',
        inDeliveryEntityParentId as any
      );
    }
    if (inDeliveryEntityId !== undefined && inDeliveryEntityId !== null) {
      queryParameters.append('inDeliveryEntityId', inDeliveryEntityId as any);
    }
    if (inCustomerId !== undefined && inCustomerId !== null) {
      queryParameters.append('inCustomerId', inCustomerId as any);
    }
    if (inCustomerType !== undefined && inCustomerType !== null) {
      queryParameters.append('inCustomerType', inCustomerType as any);
    }
    if (inCustomerSegment !== undefined && inCustomerSegment !== null) {
      queryParameters.append('inCustomerSegment', inCustomerSegment as any);
    }
    if (inCustomerNetwork !== undefined && inCustomerNetwork !== null) {
      queryParameters.append('inCustomerNetwork', inCustomerNetwork as any);
    }
    if (inProductWarrantyCode !== undefined && inProductWarrantyCode !== null) {
      queryParameters.append(
        'inProductWarrantyCode',
        inProductWarrantyCode as any
      );
    }
    if (inProductGradeCode !== undefined && inProductGradeCode !== null) {
      queryParameters.append('inProductGradeCode', inProductGradeCode as any);
    }
    if (inCatalogCatItemId !== undefined && inCatalogCatItemId !== null) {
      queryParameters.append('inCatalogCatItemId', inCatalogCatItemId as any);
    }
    if (inCatalogModelId !== undefined && inCatalogModelId !== null) {
      queryParameters.append('inCatalogModelId', inCatalogModelId as any);
    }
    if (inCatalogProductId !== undefined && inCatalogProductId !== null) {
      queryParameters.append('inCatalogProductId', inCatalogProductId as any);
    }
    if (inManufacturerId !== undefined && inManufacturerId !== null) {
      queryParameters.append('inManufacturerId', inManufacturerId as any);
    }
    if (inNotManufacturerId !== undefined && inNotManufacturerId !== null) {
      queryParameters.append('inNotManufacturerId', inNotManufacturerId as any);
    }
    if (inPickupType !== undefined && inPickupType !== null) {
      queryParameters.append('inPickupType', inPickupType as any);
    }
    if (inCarrier !== undefined && inCarrier !== null) {
      queryParameters.append('inCarrier', inCarrier as any);
    }
    if (headerId !== undefined && headerId !== null) {
      queryParameters.append('headerId', headerId as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchLogisticConfigRulesResponse>(
      `${this.basePath}/rules/logisticConfigRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a logisticConfigRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param processId
   * @param rulesId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyLogisticConfigRule(
    token: string,
    orgCode: string,
    processId: number,
    rulesId: number,
    body?: LogisticConfigRule,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<LogisticConfigRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyLogisticConfigRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyLogisticConfigRule.'
      );
    }

    if (processId === null || processId === undefined) {
      throw new Error(
        'Required parameter processId was null or undefined when calling modifyLogisticConfigRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling modifyLogisticConfigRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<LogisticConfigRule>(
      `${this.basePath}/rules/logisticConfigRules/${encodeURIComponent(
        String(processId)
      )}/${encodeURIComponent(String(rulesId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a logisticConfigRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param processId
   * @param rulesId
   * @param body
   * @param mapLevel
   */
  public replaceLogisticConfigRule(
    token: string,
    orgCode: string,
    processId: number,
    rulesId: number,
    body?: LogisticConfigRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticConfigRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceLogisticConfigRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceLogisticConfigRule.'
      );
    }

    if (processId === null || processId === undefined) {
      throw new Error(
        'Required parameter processId was null or undefined when calling replaceLogisticConfigRule.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling replaceLogisticConfigRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<LogisticConfigRule>(
      `${this.basePath}/rules/logisticConfigRules/${encodeURIComponent(
        String(processId)
      )}/${encodeURIComponent(String(rulesId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a screeningStateRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param mapLevel
   */
  public getScreeningStateRule(
    token: string,
    orgCode: string,
    rulesId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ScreeningStateRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getScreeningStateRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getScreeningStateRules.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling getScreeningStateRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<ScreeningStateRule>(
      `${this.basePath}/rules/screeningStateRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search screeningStateRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param asc
   * @param desc
   * @param orderByIgnoreCase
   * @param rulesName
   * @param outScreeningPass
   * @param outScreeningStateCode
   * @param outScreeningStateReason
   * @param inScreeningType
   * @param inStepAnswers
   * @param inBuyerEntityId
   * @param inManufacturerId
   * @param inNotManufacturerId
   * @param inCatalogCatItemId
   * @param inCatalogModelId
   * @param inCatalogProductId
   * @param inChannel
   * @param inCustomerId
   * @param inCustomerType
   * @param inCustomerSegment
   * @param inCustomerNetwork
   * @param inScreeningFormCode
   * @param inGradeType
   * @param inEntityType
   * @param inEntityNetwork
   * @param inEntityParentId
   * @param inEntityId
   * @param headerId
   */
  public getScreeningStateRules(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    asc?: Array<string>,
    desc?: Array<string>,
    orderByIgnoreCase?: boolean,
    rulesName?: string,
    outScreeningPass?: boolean,
    outScreeningStateCode?: string,
    outScreeningStateReason?: string,
    inScreeningType?: string,
    inStepAnswers?: string,
    inBuyerEntityId?: number,
    inManufacturerId?: number,
    inNotManufacturerId?: number,
    inCatalogCatItemId?: number,
    inCatalogModelId?: number,
    inCatalogProductId?: number,
    inChannel?: string,
    inCustomerId?: number,
    inCustomerType?: string,
    inCustomerSegment?: string,
    inCustomerNetwork?: string,
    inScreeningFormCode?: string,
    inGradeType?: string,
    inEntityType?: string,
    inEntityNetwork?: string,
    inEntityParentId?: number,
    inEntityId?: number,
    headerId?: number
  ): Promise<AxiosResponse<SearchScreeningStateRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getScreeningStateRules1.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getScreeningStateRules1.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (asc) {
      asc.forEach((element) => {
        queryParameters.append('asc', <any>element);
      });
    }
    if (desc) {
      desc.forEach((element) => {
        queryParameters.append('desc', <any>element);
      });
    }
    if (orderByIgnoreCase !== undefined && orderByIgnoreCase !== null) {
      queryParameters.append('orderByIgnoreCase', orderByIgnoreCase as any);
    }
    if (rulesName !== undefined && rulesName !== null) {
      queryParameters.append('rulesName', rulesName as any);
    }
    if (outScreeningPass !== undefined && outScreeningPass !== null) {
      queryParameters.append('outScreeningPass', outScreeningPass as any);
    }
    if (outScreeningStateCode !== undefined && outScreeningStateCode !== null) {
      queryParameters.append(
        'outScreeningStateCode',
        outScreeningStateCode as any
      );
    }
    if (
      outScreeningStateReason !== undefined &&
      outScreeningStateReason !== null
    ) {
      queryParameters.append(
        'outScreeningStateReason',
        outScreeningStateReason as any
      );
    }
    if (inScreeningType !== undefined && inScreeningType !== null) {
      queryParameters.append('inScreeningType', inScreeningType as any);
    }
    if (inStepAnswers !== undefined && inStepAnswers !== null) {
      queryParameters.append('inStepAnswers', inStepAnswers as any);
    }
    if (inBuyerEntityId !== undefined && inBuyerEntityId !== null) {
      queryParameters.append('inBuyerEntityId', inBuyerEntityId as any);
    }
    if (inManufacturerId !== undefined && inManufacturerId !== null) {
      queryParameters.append('inManufacturerId', inManufacturerId as any);
    }
    if (inNotManufacturerId !== undefined && inNotManufacturerId !== null) {
      queryParameters.append('inNotManufacturerId', inNotManufacturerId as any);
    }
    if (inCatalogCatItemId !== undefined && inCatalogCatItemId !== null) {
      queryParameters.append('inCatalogCatItemId', inCatalogCatItemId as any);
    }
    if (inCatalogModelId !== undefined && inCatalogModelId !== null) {
      queryParameters.append('inCatalogModelId', inCatalogModelId as any);
    }
    if (inCatalogProductId !== undefined && inCatalogProductId !== null) {
      queryParameters.append('inCatalogProductId', inCatalogProductId as any);
    }
    if (inChannel !== undefined && inChannel !== null) {
      queryParameters.append('inChannel', inChannel as any);
    }
    if (inCustomerId !== undefined && inCustomerId !== null) {
      queryParameters.append('inCustomerId', inCustomerId as any);
    }
    if (inCustomerType !== undefined && inCustomerType !== null) {
      queryParameters.append('inCustomerType', inCustomerType as any);
    }
    if (inCustomerSegment !== undefined && inCustomerSegment !== null) {
      queryParameters.append('inCustomerSegment', inCustomerSegment as any);
    }
    if (inCustomerNetwork !== undefined && inCustomerNetwork !== null) {
      queryParameters.append('inCustomerNetwork', inCustomerNetwork as any);
    }
    if (inScreeningFormCode !== undefined && inScreeningFormCode !== null) {
      queryParameters.append('inScreeningFormCode', inScreeningFormCode as any);
    }
    if (inGradeType !== undefined && inGradeType !== null) {
      queryParameters.append('inGradeType', inGradeType as any);
    }
    if (inEntityType !== undefined && inEntityType !== null) {
      queryParameters.append('inEntityType', inEntityType as any);
    }
    if (inEntityNetwork !== undefined && inEntityNetwork !== null) {
      queryParameters.append('inEntityNetwork', inEntityNetwork as any);
    }
    if (inEntityParentId !== undefined && inEntityParentId !== null) {
      queryParameters.append('inEntityParentId', inEntityParentId as any);
    }
    if (inEntityId !== undefined && inEntityId !== null) {
      queryParameters.append('inEntityId', inEntityId as any);
    }
    if (headerId !== undefined && headerId !== null) {
      queryParameters.append('headerId', headerId as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchScreeningStateRulesResponse>(
      `${this.basePath}/rules/screeningStateRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a screeningStateRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createScreeningStateRule(
    token: string,
    orgCode: string,
    body?: ScreeningStateRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ScreeningStateRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createScreeningStateRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createScreeningStateRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<ScreeningStateRule>(
      `${this.basePath}/rules/screeningStateRules`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a screeningStateRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   */
  public modifyScreeningStateRule(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: ScreeningStateRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ScreeningStateRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyScreeningStateRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyScreeningStateRules.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling modifyScreeningStateRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<ScreeningStateRule>(
      `${this.basePath}/rules/screeningStateRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a screeningStateRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   * @param body
   * @param mapLevel
   */
  public replaceScreeningStateRules(
    token: string,
    orgCode: string,
    rulesId: number,
    body?: ScreeningStateRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ScreeningStateRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceScreeningStateRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceScreeningStateRules.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling replaceScreeningStateRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<ScreeningStateRule>(
      `${this.basePath}/rules/screeningStateRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a screeningStateRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param rulesId
   */
  public deleteScreeningStateRule(
    token: string,
    orgCode: string,
    rulesId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteScreeningStateRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteScreeningStateRules.'
      );
    }

    if (rulesId === null || rulesId === undefined) {
      throw new Error(
        'Required parameter rulesId was null or undefined when calling deleteScreeningStateRules.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/rules/screeningStateRules/${encodeURIComponent(
        String(rulesId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * modify screeningStateRules by formCode
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param formCode
   * @param body
   * @param mapLevel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public modifyScreeningStateRulesByFormCode(
    token: string,
    orgCode: string,
    formCode: string,
    body?: Array<ScreeningStateRule>,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<SearchScreeningStateRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyScreeningStateRulesByFormCode.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyScreeningStateRulesByFormCode.'
      );
    }

    if (formCode === null || formCode === undefined) {
      throw new Error(
        'Required parameter formCode was null or undefined when calling modifyScreeningStateRulesByFormCode.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<SearchScreeningStateRulesResponse>(
      `${this.basePath}/rules/screeningStateRules/${encodeURIComponent(
        String(formCode)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search apiRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param request
   */
  public getApiRules(
    token: string,
    orgCode: string,
    request: SearchApiRulesRequest
  ): Promise<AxiosResponse<SearchApiRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getApiRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getApiRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (request) {
      for (const [key, value] of Object.entries(request)) {
        if (Array.isArray(value)) {
          for (const v of value) {
            queryParameters.append(key, v as any);
          }
        } else {
          queryParameters.append(key, value as any);
        }
      }
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.get<SearchProcessRulesResponse>(
      `${this.basePath}/rules/apiRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search stockCodeRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param request
   */
  public getStockCodeRules(
    token: string,
    orgCode: string,
    request: SearchStockCodeRulesRequest
  ): Promise<AxiosResponse<SearchStockCodeRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getStockCodeRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getStockCodeRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (request) {
      for (const [key, value] of Object.entries(request)) {
        if (Array.isArray(value)) {
          for (const v of value) {
            queryParameters.append(key, v as any);
          }
        } else {
          queryParameters.append(key, value as any);
        }
      }
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.get<SearchProcessRulesResponse>(
      `${this.basePath}/rules/stockCodeRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search treatmentOperationRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param request
   */
  public getTreatmentOperationRules(
    token: string,
    orgCode: string,
    request: SearchTreatmentOperationRulesRequest
  ): Promise<AxiosResponse<SearchTreatmentOperationRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTreatmentOperationRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTreatmentOperationRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (request) {
      for (const [key, value] of Object.entries(request)) {
        if (Array.isArray(value)) {
          for (const v of value) {
            queryParameters.append(key, v as any);
          }
        } else {
          queryParameters.append(key, value as any);
        }
      }
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.get<SearchProcessRulesResponse>(
      `${this.basePath}/treatments/treatmentOperationRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search warrantyCalculationRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param request
   */
  public getWarrantyCalculationRules(
    token: string,
    orgCode: string,
    request: SearchWarrantyCalculationRulesRequest
  ): Promise<AxiosResponse<SearchWarrantyCalculationRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getWarrantyCalculationRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getWarrantyCalculationRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (request) {
      for (const [key, value] of Object.entries(request)) {
        if (Array.isArray(value)) {
          for (const v of value) {
            queryParameters.append(key, v as any);
          }
        } else {
          queryParameters.append(key, value as any);
        }
      }
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    return axios.get<SearchProcessRulesResponse>(
      `${this.basePath}/rules/warrantyCalculationRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get listValueRules like catalogSymptom and catalogFault
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param enable
   * @param mapLevel
   * @param ruleType
   * @param productId
   * @param modelId
   * @param manufacturerId
   * @param productCatItemId
   */
  public searchListValueRules(
    token: string,
    orgCode: string,
    enable?: boolean,
    mapLevel?: Array<string>,
    ruleType?: string,
    productId?: number,
    modelId?: number,
    manufacturerId?: number,
    productCatItemId?: number
  ): Promise<AxiosResponse<SearchListValueRulesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling searchListValueRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling searchListValueRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }
    if (ruleType !== undefined && ruleType !== null) {
      queryParameters.append('ruleType', ruleType as any);
    }
    if (productId !== undefined && productId !== null) {
      queryParameters.append('productId', productId as any);
    }
    if (modelId !== undefined && modelId !== null) {
      queryParameters.append('modelId', modelId as any);
    }
    if (manufacturerId !== undefined && manufacturerId !== null) {
      queryParameters.append('manufacturerId', manufacturerId as any);
    }
    if (productCatItemId !== undefined && productCatItemId !== null) {
      queryParameters.append('productCatItemId', productCatItemId as any);
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchListValueRulesResponse>(
      `${this.basePath}/rules/listValueRules`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
}
