/**
 * gc-ws-backoffice
 * GC WS BackOffice API.
 *
 * OpenAPI spec version: 5.0.0-SNAPSHOT
 * Contact: acuvillier@sbeglobalservice.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Configuration } from 'src/api/api-gc/configuration';
import config from 'src/config';
import axios, { AxiosResponse } from 'axios';
import {
  CatalogAccessory,
  CatalogBom,
  CatalogFault,
  CatalogParam,
  CatalogParamValue,
  CatalogProduct,
  CatalogProductLnGroup,
  CatalogReference,
  CatalogSymptom,
  DatecodePattern,
  Manufacturer,
  ManufacturerFilter,
  ManufacturerLnGroup,
  ManufacturerParam,
  ManufacturerParamValue,
  SearchCatalogAccessoriesResponse,
  SearchCatalogBomsResponse,
  SearchCatalogFaultsResponse,
  SearchCatalogParamsResponse,
  SearchCatalogProductsResponse,
  SearchCatalogReferencesResponse,
  SearchCatalogSymptomsResponse,
  SearchDatecodePatternsResponse,
  SearchManufacturerParamsResponse,
  SearchManufacturersResponse,
  SearchSlaThresholdResponse,
  SlaThreshold,
} from 'src/api/api-gc/model';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APISlaService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * get a SlaThreshold
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param slaThresholdId
   * @param mapLevel
   */
  public getSlaThreshold(
    token: string,
    orgCode: string,
    slaThresholdId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<SlaThreshold>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceDatecodePattern.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceDatecodePattern.'
      );
    }

    if (slaThresholdId === null || slaThresholdId === undefined) {
      throw new Error(
        'Required parameter slaThresholdId was null or undefined when calling replaceDatecodePattern.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    console.log(headers);

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SlaThreshold>(
      `${this.basePath}/sla/slaThreshold/${slaThresholdId}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
  /**
   * Update a slaThreshold
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param slaThresholdId
   * @param body
   * @param mapLevel
   */
  public replaceSlaThreshold(
    token: string,
    orgCode: string,
    slaThresholdId: number,
    body?: SlaThreshold,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<SlaThreshold>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceDatecodePattern.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceDatecodePattern.'
      );
    }

    if (slaThresholdId === null || slaThresholdId === undefined) {
      throw new Error(
        'Required parameter slaThresholdId was null or undefined when calling replaceDatecodePattern.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<SlaThreshold>(
      `${this.basePath}/sla/slaThreshold/${slaThresholdId}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
  /**
   * Remove a slaThreshold
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param accId
   */
  public removeSlaThreshold(
    token: string,
    orgCode: string,
    slaThresholdId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeCatalogAccessory.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeCatalogAccessory.'
      );
    }

    if (slaThresholdId === null || slaThresholdId === undefined) {
      throw new Error(
        'Required parameter slaThresholdId was null or undefined when calling removeCatalogAccessory.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/sla/slaThreshold/${slaThresholdId}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
  /**
   * Modify a slaThreshold
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param slaThresholdId
   * @param body
   * @param mapLevel
   */
  public modifySlaThreshold(
    token: string,
    orgCode: string,
    slaThresholdId: number,
    body?: SlaThreshold,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogAccessory>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyCatalogAccessory.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyCatalogAccessory.'
      );
    }

    if (slaThresholdId === null || slaThresholdId === undefined) {
      throw new Error(
        'Required parameter slaThresholdId was null or undefined when calling modifyCatalogAccessory.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<SlaThreshold>(
      `${this.basePath}/sla/slaThreshold/${slaThresholdId}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
  /**
   * get a list og SlaThreshold
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param slaThresholdId
   * @param mapLevel
   */
  public getSlaThresholds(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<SearchSlaThresholdResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceDatecodePattern.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceDatecodePattern.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.get<SearchSlaThresholdResponse>(
      `${this.basePath}/sla/slaThreshold/`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * create SlaThreshold
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createSlaThreshold(
    token: string,
    orgCode: string,
    body: SlaThreshold,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<SlaThreshold>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceDatecodePattern.'
      );
    }
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling replaceDatecodePattern.'
      );
    }
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceDatecodePattern.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }
    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<SlaThreshold>(`${this.basePath}/sla/slaThreshold/`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }
}
